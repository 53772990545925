html, body, #root {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: #fff;
}

iframe {
    border: 0;
}

figcaption,
figure,
main {
    display: block;
}

main {
    overflow: hidden;
}

.pointer {
    cursor: pointer;
}