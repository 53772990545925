.login-style {
	text-align: center;
}
.mar-top-bot {
	margin: 2% 0%;
}
.logo-space {
	text-align: center;
	width: 100%;
}
.btn-border {
	border: 1px solid rgba(0, 0, 0, 0.25);
}
.text-steps-font {
	font-weight: 600;
}
.ul-list-style {
	list-style-type: disc !important;
}

.input-text-style::placeholder {
	color: grey !important;
}

#promotion-page {
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 60vh;

	@media (max-width: 992px) {
		min-height: 40vh;
	}

	.promotion-container {
		overflow: hidden;

		.book-now-btn {
			background-color: #764979;
			border-radius: 20px 0px;
			font-family: 'DM Sans', sans-serif;
			font-weight: 500;
			color: white;
			padding: 14px 40px;
			transition: all 100ms ease-out;
			border: none;
			width: fit-content;

			&:hover {
				background-color: #764979;
				opacity: 0.8;
			}

			@media (max-width: 992px) {
				border-radius: 10px 0px;
				padding: 7px 20px;
			}
		}

		.promotion-img {
			height: 100%;
			width: auto;
			object-fit: contain;
		}
	}
}
#home-page {
	background-color: #fff;
	position: relative;

	#banner-page {
		min-height: 100vh;
		position: relative;

		.banner-container {
			min-height: 80%;

			.image-holder-col {
				position: relative;

				@media (max-width: 992px) {
					order: 1 !important;
					padding: 10px;
				}

				.colored-yoga-svg {
					position: absolute;
					bottom: 0;
					left: 10px;
					width: 30%;
					object-fit: contain;
				}

				.banner-image-wrapper {
					height: 90%;
					width: 80%;
					padding: 10px;
					float: right;

					.banner-image {
						height: 100%;
						width: 100%;
						object-fit: cover;
						border-top-left-radius: 1000px;
						border-top-right-radius: 1000px;
					}
				}
			}

			.content-holder-col {
				@media (max-width: 992px) {
					order: 2 !important;
				}

				.small-flower-svg {
					height: 90px;
					width: 90px;
					object-fit: contain;
				}

				.banner-tagline {
					font-family: 'DM Sans', sans-serif;
					font-weight: 500;
					color: #808080;
					text-transform: uppercase;
					text-align: center;
				}

				.banner-heading {
					font-family: 'Abril Fatface', cursive;
					font-weight: 400;
					color: #333333;
					white-space: pre-line;
				}

				.banner-subheading {
					font-family: 'DM Sans', sans-serif;
					font-weight: 500;
					color: #808080;
					white-space: pre-line;
				}

				.buttons-container {
					gap: 10px;
					.banner-button {
						width: fit-content;
						border: none;
						background-color: #27392a;
						font-family: 'DM Sans', sans-serif;
						font-weight: 700;
						padding: 10px 35px;
						border-radius: 0px 20px;
						color: #fff;
						a {
							color: #fff !important;
						}
					}
				}
			}
		}
	}
}
