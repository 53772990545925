@media screen and (max-width: 400px) {
    .img-height-fixed {
        height: 100px !important;
        width: 100px !important;
    }
}

@media screen and (min-width: 401px) and (max-width: 1080px) {
    .img-height-fixed {
        height: 110px !important;
        width: 110px !important;
    }
}

@media screen and (min-width: 1081px) {
    .img-height-fixed {
        height: 140px !important;
        width:140px !important;
    }
    .card-body{
        padding:20px !important;
    }
}

.card-body{
    padding:10px;
}

.card-imgs{
    border-radius: 20px !important;
}
