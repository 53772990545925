#affilations-container{
    .affilation-card {
        border-top-left-radius: 48px !important;
        border-bottom-right-radius: 48px !important;
        transition: all 0.3s;
        background-color: white;
    }
    .affilation-card:hover {
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        scale: 1.025;
    }
}