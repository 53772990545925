#packages-page {

    h1 {
        font-family: 'Abril Fatface', cursive;
        text-align: center;
    }

    h3 {
        text-align: center;
        font-family: 'DM Sans', sans-serif;
        font-weight: 500;

    }

    #banner-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 30vh;
        margin: auto;
        gap: 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.25);

        h3 {
            max-width: 70%;
            color: rgba(0, 0, 0, 0.50);
        }

        .breadcrumb-button {
            border-radius: 0 25px 0 25px;
            background-color: #764979;
            padding: 15px;
        }

    }

    #package-section {
        padding-top: 100px;
        padding-bottom: 100px;

        h4 {
            color: rgba(0, 0, 0, 0.50);
            text-align: center;
            font-family: 'DM Sans', sans-serif;
            font-size: 15px;
            font-weight: 700;
            margin-bottom: 10px;
            text-transform: uppercase;
        }

        h3 {
            color: rgba(0, 0, 0, 0.50);
        }

        #tabs {
            background-color: #0000000E;
            border-radius: 30px;
            height: 60px;
            padding: 5px;
            display: flex;
            gap: 5px;
            margin: 20px 0 30px 0;

            .tabCell {
                border-radius: 30px;
                height: 100%;
                width: 20%;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                transition: all 0.3s ease-in-out;
                p {
                    user-select: none;
                    margin: 10px 0px !important;
                }
                &:hover {
                    background-color: white;
                    box-shadow: 0px 2px 26px 0px rgba(0, 0, 0, 0.15);
                    scale: 1.05;
                }
            }

            .tabCellActive {
                background-color: white;
                box-shadow: 0px 2px 26px 0px rgba(0, 0, 0, 0.15);
                &:hover {
                    background-color: white;
                    box-shadow: 0px 2px 26px 0px rgba(0, 0, 0, 0.15);
                    scale: 1;
                }
            }

            .tabText {
                text-align: center;
                margin: auto;
                font-family: 'DM Sans', sans-serif;
                font-weight: 600;
            }
        }

        @media (max-width: 768px) {
            #tabs {
                flex-direction: column;
                height: auto;
                gap: 10px;
                margin: 20px 0 30px 0;

                .tabCell {
                    width: 100%;
                }
            }
        }

        .description-card {
            p {
                white-space: pre-line;
                font-family: 'DM Sans', sans-serif;
                font-weight: 500;
            }
            span {
                font-family: 'DM Sans', sans-serif;
                font-weight: 500;
            }
            .pointer-with-href {
                cursor: pointer;
                color: #764979;
                text-decoration: underline;
                font-family: 'DM Sans', sans-serif;
                font-weight: 500;
            }
        }

        .package-card {
            display: flex;
            margin-top: 20px;
            border-radius: 0px 25px 0px 25px;
            box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
            transition: all 0.3s ease-in-out;
            &:hover {
                transform: scale(1.05);
                box-shadow: 0px 2px 26px 0px rgba(0, 0, 0, 0.15);
            }

            img {
                margin: 20px;
                object-fit: cover;
                margin: auto;
            }

            h3 {
                font-family: 'Abril Fatface', cursive;
                color: black
            }

            .middle-section {
                background: #F1D0FF;
                display: flex;
                padding: 20px;
                gap: 10px;
                column-gap: 10px;
                flex-direction: column;
            }

            .learnmore {
                color: #000;
                font-family: 'DM Sans', sans-serif;
                font-size: 20px;
                font-weight: 500;
                margin-top: auto;
            }

            .description {
                font-family: 'DM Sans', sans-serif;
                color: rgba(0, 0, 0, 0.50);
                font-size: 14px;
            }

            .price-div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 20px;
                gap: 15px;
                background-color: #764979;

                .button {
                    border-radius: 25px 0px 25px 0px;
                    background-color: #C8A97D;
                    padding: 10px 20px;
                    border: none;
                    font-family: 'DM Sans', sans-serif;
                    color: #FFF;
                    font-size: 18px;
                    font-weight: 500;
                    text-transform: capitalize;
                }
            }
        }
    }
}