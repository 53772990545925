@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700");

.landing-site {

  #scrollUp {
    position: fixed;
    right: 25px;
    bottom: 25px;
    width: 32px;
    height: 32px;
    padding: 0;
    border: none;
    background: url("https://kwm-user-data.s3.eu-west-2.amazonaws.com/kwm_web/up.png") no-repeat center center;
  }

  body {
    font-family: Helvetica, Sans-Serif;
    color: #ffffff !important;
    font-size: 14px;
    line-height: 1.6em;
    -webkit-backface-visibility: hidden;
  }

  ::-moz-selection {
    background: #1bbde8;
    color: #fff;
    text-shadow: none;
    outline: none;
  }

  ::-webkit-input-placeholder {
    /* Chrome */
    color: #fff;
    opacity: 1;
  }

  :-ms-input-placeholder {
    /* IE 10+ */
    color: #fff;
    opacity: 1;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    color: #fff;
    opacity: 1;
  }

  .first-child {
    padding-top: 0 !important;
    border-top: inherit !important;
  }

  .overlay {
    position: relative;
  }

  .overlay::before {
    background: #171717;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.8;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .page-head.overlay:before {
    background: #010528;
    content: "";
    opacity: 0.6;
  }

  .overlay .section-title h3 {
    color: #fff;
  }

  .primary-overlay {
    position: relative;
    width: 100%;
    display: block;
  }

  .primary-overlay:before {
    background: rgba(252, 50, 138, 0.6);
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    transition: all 0.5s ease 0s;
  }

  .primary-overlay:hover:before {
    opacity: 1;
  }

  .p-bg.primary-overlay:before {
    background: rgba(252, 50, 138, 0.8);
    opacity: 1;
  }

  .ovrlay {
    color: #fff;
    cursor: pointer;
    position: absolute;
    top: 45%;
    left: 45%;
    font-size: 30px;
    transition: all 0.5s ease 0s;
    opacity: 0;
  }

  .bttn {
    width: 100%;
    display: block;
    text-align: center;
  }

  .bttn .btn {
    background: #e4e4e4;
    border: 1px solid transparent;
    color: #171717;
    cursor: pointer;
    display: inline-block;
    font-size: 15px;
    font-weight: 700;
    outline: 0 none;
    position: relative;
    padding: 15px 30px;
    transition: all 0.5s ease 0s;
    border: 1px solid #d7d7d7;
  }

  .bttn .btn:hover,
  .bttn .btn.active,
  .btn-primary:not([disabled]):not(.disabled).active,
  .btn-primary:not([disabled]):not(.disabled):active,
  .show>.btn-primary.dropdown-toggle {
    background: #fc328a;
    color: #fff !important;
    box-shadow: inherit;
    border: 1px solid transparent !important;
  }

  .bttn .btn.active:hover {
    background: #e20d69;
  }

  .bold,
  .section-title p,
  .slider-area1 .metis.tparrows:before,
  .call-to-action1 .cta-box h5.title,
  .portfolio-area .port-box .port-dtl h5,
  .pricing-area .price-box .price-quantity .qnty-box .box-element h5,
  .pricing-area .price-box .price-quantity .qnty-box .box-element p,
  .schedule-area .dwnload a,
  .schedule-area2 .schdl-tab-area2.nav .nav-item .nav-link,
  .tab-action .cta-box .nav-tabs .schedule-area2 .schdl-tab-area2.nav .nav-item.show .nav-link,
  .schedule-area2 .schdl-tab-area2.nav .single-tab-area .single-tab .nav-tabs .nav-item.show .nav-link,
  .single-tab-area .single-tab .nav-tabs .schedule-area2 .schdl-tab-area2.nav .nav-item.show .nav-link,
  .blog-box .blog-content .blog-title a,
  .blog-area .portfolio-nav ul li,
  .about-area .aboutus-box .about-benefit a,
  .about-area .about-opening .opening-hours h3,
  .about-area .about-opening .opening-hours ul li,
  .about-area .about-opening .opening-img .about-nd h5,
  .about-area .about-opening .opening-img .about-nd p,
  .trainers-area .trainer-pro .trainer-bio h4,
  .counter-area .single-counter p,
  .error-page-area .content-full a {
    font-weight: 700;
  }

  ul {
    list-style: outside none none;
    margin-bottom: 0;
    padding-left: 0;
  }

  .breadcrumb {
    background-color: rgba(0, 0, 0, 0);
    justify-content: center;
  }

  .breadcrumb li {
    color: white;
    display: inline-block;
    font-size: 18px;
    font-weight: 400;
    text-transform: capitalize;
    margin: 0 5px;
  }

  .breadcrumb li a {
    color: #fc328a;
  }

  .page-head {
    padding: 160px 100px 150px;
  }

  .page-head .section-title h3 {
    font-size: 55px;
    margin-bottom: 20px;
    color: #fff;
  }

  textarea#text-area {
    height: 220px;
  }

  element.style {}

  .slick-slide img {
    display: block;
    /* margin-right: 16px; */
  }

  .features-area.pb30 .img-fluid {
    width: 96%;
  }

  .testimonial-down .img-fluid {
    width: 96%;
  }

  .wht-txt {
    color: #fff important;
  }

  .form-group {
    margin-bottom: 1.5rem;
  }

  .form-control {
    padding: 17px 16px;
    font-size: 14px;
    border: 1px solid #ced4da;
    border-radius: 8px;
  }

  /*.form-control:hover, .form-control:focus {
  border: 1px solid #fc328a;
  box-shadow: inherit; }*/

  select.form-control:not([size]):not([multiple]) {
    height: inherit;
  }

  textarea {
    height: 210px;
  }

  .img3 {
    width: 30.33%;
    float: left;
  }

  .img-center {
    margin-left: auto;
    margin-right: auto;
  }

  .ttu,
  .bttn .btn,
  .section-title h3,
  .header .navbar .navbar-nav .nav-item .nav-link,
  .header .navbar .navbar-nav .dropdown-menu a,
  .slider-area1 .tp-caption.slide-text-one h1,
  .slider-area1 .tp-caption.slide-text-one h2,
  .slider-area1 .tp-caption.slide-text-one h3,
  .slider-area1 .tp-caption.slide-text-one h4,
  .slider-area1 .tp-caption.slide-text-one h5,
  .slider-area1 .tp-caption.slide-text-one h6,
  .slider-area1 .tp-caption.slide-text-two h1,
  .slider-area1 .tp-caption.slide-text-two h2,
  .slider-area1 .tp-caption.slide-text-two h3,
  .slider-area1 .tp-caption.slide-text-two h4,
  .slider-area1 .tp-caption.slide-text-two h5,
  .slider-area1 .tp-caption.slide-text-two h6,
  .features-area .features-body .features-box .features-elements h4,
  .call-to-action1 .cta-box h5.title,
  .tab-action .cta-box h4,
  .tab-action .cta-box .nav-tabs .nav-link,
  .tab-action .cta-box .nav-tabs .nav-item.show .nav-link,
  .portfolio-area .port-box .port-dtl h5,
  .port-area2 .portfolio-nav ul li,
  .pricing-area .price-box .price-quantity .qnty-box .box-element h5,
  .pricing-area .price-box .price-quantity .qnty-box .box-element p,
  .schedule-area .tab-content .tab-pane .schdl-box,
  .schedule-area .dwnload a,
  .schedule-area2 .schdl-tab-area2.nav .nav-item .nav-link,
  .schedule-area2 .schedule-tab-content .tab-pane ul li .schedule-desk h5,
  .product-area .product-box .cart-box .cart-element a,
  .product-area .product-box .cart-dtl,
  .blog-area .portfolio-nav ul li,
  .about-area .about-opening .opening-hours h3,
  .about-area .about-opening .opening-img .about-nd h5,
  .about-area .about-opening .opening-img .about-nd p,
  .trainers-area .trainer-pro .trainer-bio h4,
  .feature-service .features-box h4,
  .bmi-box .form-group .bttn .btn,
  .features-goal .features-box h4,
  .single-tab-area .single-tab .nav-tabs .nav-item .nav-link,
  .single-tab-area .single-tab .nav-tabs .nav-item.show .nav-link,
  .single-tab-area .subscribe-box .subs-title h4,
  .single-tab-area .subscribe-box .subscribe a,
  .single-tab-area .single-tab-content .services-content .service-title h4,
  .counter-area .single-counter p,
  .contact-area h4,
  .error-page-area .content-full a,
  .single-class .single-content .single-title h4,
  .single-class .search-box input::placeholder,
  .single-class .single-list h4,
  .single-class .single-list ul li a,
  .booking-schedule ul li,
  .single-information-area .product-info h3 a,
  .guest-register h5,
  .guest-register .login a,
  .footer-area .news-info .footer-title h3,
  .footer-area .instra h4 {
    text-transform: uppercase;
  }

  .ttp,
  .section-title p,
  .call-to-action1 .cta-box h4.sub-title,
  .tab-action .cta-box h5,
  .schedule-area2 .schedule-tab-content .tab-pane ul li .schedule-desk h3,
  .blog-box .blog-content .blog-title a,
  .blog-box .blog-content .blog-footer a,
  .testimonial-area .testimonial-box .test-content .person h4,
  .testimonial-area .testimonial-box .test-content .person h5,
  .about-area .aboutus-box .about-benefit a,
  .about-area .about-opening .opening-hours ul li,
  .bmi-box .form-group h4,
  .single-tab-area .subscribe-box .subscribe input::placeholder,
  .error-page-area .content-full p,
  .single-information-area .product-info .pro-rating .review p,
  .table-content table td.product-name a,
  .guest-register p,
  .guest-register ul li a,
  .form-group h4 {
    text-transform: capitalize;
  }

  .full-width {
    width: 100%;
  }

  i {
    color: #fff;
  }

  a {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
  }

  a:focus {
    text-decoration: none;
    color: #ffffff;
    outline: 0;
  }

  .section-title h3 {
    color: #171717;
    font-size: 40px;
    line-height: 40px;
  }

  .section-title p {
    font-size: 18px;
    color: #444;
    margin: 20px 0 50px;
  }

  .title-white .section-title h3 {
    color: #fff;
  }

  .title-white .section-title p {
    color: #fff;
    font-weight: 400;
  }

  .packages ul li {
    text-align: left;
  }

  .section-title.text-center p {
    color: #fff;
    font-weight: 400;
  }

  .pricing-area.bg1.overlay.text-center.pad90 .price-box {
    margin-top: 23px;
  }

  .pricing-area.bg1.overlay.text-center.pad90 .price-quantity {
    text-align: left;
    margin-top: 18px;
  }

  .schedule-heading {
    text-align: left;
    color: #171717;
    font-weight: 600;
    font-size: 23px;
    line-height: 1.6;
  }

  .pricing-area.text-center.pad90 .price-box {
    margin-top: 23px;
  }

  .second-stage {
    color: #171717 !important;
    font-weight: 400;
  }

  .pricing-area.text-center.pad90 .with-first {
    margin-top: 240px;
  }

  /* 1.2  Typography */
  p {
    font-size: 14px !important;
    color: #171717;
    line-height: 1.6rem;
    letter-spacing: 0.5px;
  }

  .about-down {
    font-size: 15px;
    color: #171717;
    line-height: 2.2rem;
    letter-spacing: 0.5px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: Helvetica, Sans-Serif;
    font-weight: 700;
    margin: 0;
  }

  /* 1.3 Margins & Paddings */
  .mt10 {
    margin-top: 10px;
  }

  .mt15 {
    margin-top: 15px;
  }

  .mt60 {
    margin-top: 60px;
  }

  .mt20 {
    margin-top: 20px;
  }

  .mt30 {
    margin-top: 30px;
  }

  .mt40 {
    margin-top: 40px;
  }

  .mt50 {
    margin-top: 50px;
  }

  /*.mt70 {
  margin-top: 70px; }*/

  .mb15 {
    margin-bottom: 15px;
  }

  .mb30 {
    margin-bottom: 30px;
  }

  .mb20 {
    margin-bottom: 20px;
  }

  .mb40 {
    margin-bottom: 40px;
  }

  .mb50 {
    margin-bottom: 50px;
  }

  .mb60 {
    margin-bottom: 60px;
  }

  .mb90 {
    margin-bottom: 90px;
  }

  .pad80 {
    padding: 80px 0;
  }

  .pad60 {
    padding: 60px 0;
  }

  .pad70 {
    padding: 70px 0;
  }

  .pad40 {
    padding: 40px 0;
  }

  .pad30 {
    padding: 30px 0;
  }

  .pad90 {
    padding: 60px 0;
  }

  .pad120 {
    padding: 120px 0;
  }

  .pb60 {
    padding-bottom: 60px;
  }

  .pb40 {
    padding-bottom: 40px;
  }

  .pb30 {
    padding-bottom: 30px;
  }

  .pb90 {
    padding-bottom: 90px;
  }

  .pt60 {
    padding-top: 60px;
  }

  .pt90 {
    padding-top: 90px;
  }

  .pt120 {
    padding-top: 120px;
  }

  .pt20 {
    padding-top: 20px;
  }

  .pt40 {
    padding-top: 40px;
  }

  .pt50 {
    padding-top: 50px;
  }

  .no-pad {
    padding: 0;
  }

  /* 1.4 Backgrounds */
  /*.bg1 {
  background: rgba(0, 0, 0, 0) url("../images/bg/bg1.jpg"); }*/

  .bg2 {
    background: rgba(0, 0, 0, 0) url("https://kwm-user-data.s3.eu-west-2.amazonaws.com/kwm_web/bg2.jpg");
  }

  /*.bg3 {
  background: rgba(0, 0, 0, 0) url("../images/bg/bg3.jpg"); }*/

  .bg4 {
    background: rgba(0, 0, 0, 0) url("https://kwm-user-data.s3.eu-west-2.amazonaws.com/kwm_web/bg4.jpg");
  }

  .bg5 {
    background: rgba(0, 0, 0, 0) url("https://kwm-user-data.s3.eu-west-2.amazonaws.com/kwm_web/bg5.jpg");
  }

  .bg6 {
    background: rgba(0, 0, 0, 0) url("https://kwm-user-data.s3.eu-west-2.amazonaws.com/kwm_web/bg6.jpg");
  }

  .bg-404 {
    background: url("https://kwm-user-data.s3.eu-west-2.amazonaws.com/kwm_web/404.jpg");
  }

  .calculate-area {
    background: rgba(0, 0, 0, 0) url("https://kwm-user-data.s3.eu-west-2.amazonaws.com/kwm_web/bg.jpg");
  }

  .about-bg {
    background: rgba(0, 0, 0, 0) url("https://kwm-user-data.s3.eu-west-2.amazonaws.com/kwm_web/about-us.jpg");
  }

  .trainer-bg {
    background: rgba(0, 0, 0, 0) url("https://kwm-user-data.s3.eu-west-2.amazonaws.com/kwm_web/trainer.jpg");
  }

  .services-bg {
    background: rgba(0, 0, 0, 0) url("https://kwm-user-data.s3.eu-west-2.amazonaws.com/kwm_web/services.jpg");
  }

  .services2-bg {
    background: rgba(0, 0, 0, 0) url("https://kwm-user-data.s3.eu-west-2.amazonaws.com/kwm_web/service.jpg");
  }

  .services3-bg {
    background: rgba(0, 0, 0, 0) url("https://kwm-user-data.s3.eu-west-2.amazonaws.com/kwm_web/packagesimg.jpg");
  }

  .recipes-bg {
    background: rgba(0, 0, 0, 0) url("https://kwm-user-data.s3.eu-west-2.amazonaws.com/kwm_web/recipes.jpg");
  }

  .pricing-plan-bg {
    background: rgba(0, 0, 0, 0) url("https://kwm-user-data.s3.eu-west-2.amazonaws.com/kwm_web/pricing.jpg");
  }

  .contact-bg {
    background: rgba(0, 0, 0, 0) url("https://kwm-user-data.s3.eu-west-2.amazonaws.com/kwm_web/contact.jpg");
  }

  .error-bg {
    background: rgba(0, 0, 0, 0) url("https://kwm-user-data.s3.eu-west-2.amazonaws.com/kwm_web/error.jpg");
  }

  .blog-bg {
    background: rgba(0, 0, 0, 0) url("https://kwm-user-data.s3.eu-west-2.amazonaws.com/kwm_web/blog.jpg");
  }

  .trans-bg {
    background: rgba(0, 0, 0, 0) url("https://kwm-user-data.s3.eu-west-2.amazonaws.com/kwm_web/trans.jpg");
  }

  .blog2-bg {
    background: rgba(0, 0, 0, 0) url("https://kwm-user-data.s3.eu-west-2.amazonaws.com/kwm_web/blog2.jpg");
  }

  .single-class-bg {
    background: rgba(0, 0, 0, 0) url("https://kwm-user-data.s3.eu-west-2.amazonaws.com/kwm_web/single-class.jpg");
  }

  .booking-bg {
    background: rgba(0, 0, 0, 0) url("https://kwm-user-data.s3.eu-west-2.amazonaws.com/kwm_web/booking.jpg");
  }

  .shop-bg {
    background: rgba(0, 0, 0, 0) url("https://kwm-user-data.s3.eu-west-2.amazonaws.com/kwm_web/shop.jpg");
  }

  .shop-cart-bg {
    background: rgba(0, 0, 0, 0) url("https://kwm-user-data.s3.eu-west-2.amazonaws.com/kwm_web/shop-cart.jpg");
  }

  .checkout-bg {
    background: rgba(0, 0, 0, 0) url("https://kwm-user-data.s3.eu-west-2.amazonaws.com/kwm_web/checkout.jpg");
  }

  .parallax {
    background-repeat: no-repeat;
    /*background-attachment: fixed;*/
    background-position: 50% 0;
    background-size: cover;
  }

  /*animation*/
  .animation {
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  @-webkit-keyframes slideDownIn {
    0% {
      -webkit-transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
    }

    0% {
      -webkit-transform: translateY(-20);
    }
  }

  @keyframes slideDownIn {
    0% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0);
    }

    0% {
      transform: translateY(-20px);
    }
  }

  .slideDownIn {
    -webkit-animation-name: slideDownIn;
    animation-name: slideDownIn;
  }

  @-webkit-keyframes slideUpIn {
    0% {
      top: 0px;
    }

    100% {
      top: 10px;
    }
  }

  @keyframes slideUpIn {
    0% {
      top: 0px;
    }

    100% {
      top: 76px;
    }
  }

  .slideUpIn {
    -webkit-animation-name: slideUpIn;
    animation-name: slideUpIn;
  }

  /*animation*/
  /*02	Navbar Styles*/

  .header .navbar.navbar-dark {
    background: #1a1a1af7;
    height: 100px;
  }

  .header .navbar .navbar-brand {
    padding: 0;
  }

  .header .navbar .navbar-nav .nav-item {
    position: relative;
  }

  .header .navbar .navbar-nav .nav-item .nav-link,
  .header .navbar .navbar-nav .tab-action .cta-box .nav-tabs .nav-item.show .nav-link,
  .tab-action .cta-box .nav-tabs .header .navbar .navbar-nav .nav-item.show .nav-link,
  .header .navbar .navbar-nav .single-tab-area .single-tab .nav-tabs .nav-item.show .nav-link,
  .single-tab-area .single-tab .nav-tabs .header .navbar .navbar-nav .nav-item.show .nav-link {
    line-height: 30px;
    text-transform: unset;
    font-size: 17px;
    color: black;
    font-weight: 600 !important;
    font-family: 'DM Sans', sans-serif;
  }

  .header .navbar .navbar-nav .nav-item .nav-link:before,
  .header .navbar .navbar-nav .tab-action .cta-box .nav-tabs .nav-item.show .nav-link:before,
  .tab-action .cta-box .nav-tabs .header .navbar .navbar-nav .nav-item.show .nav-link:before,
  .header .navbar .navbar-nav .single-tab-area .single-tab .nav-tabs .nav-item.show .nav-link:before,
  .single-tab-area .single-tab .nav-tabs .header .navbar .navbar-nav .nav-item.show .nav-link:before {
    color: transparent;
    content: "•";
    font-size: 1.2em;
    left: 50%;
    pointer-events: none;
    position: absolute;
    text-shadow: transparent 0px 0px;
    top: -25%;
    transform: translateX(-50%);
    transition: text-shadow 0.3s ease 0s, color 0.3s ease 0s;
  }

  .header .navbar .navbar-nav .nav-item .nav-link:hover {
    color: rgb(192, 166, 204);
  }

  .navbar-horizontal .navbar-brand img {
    height: 65px;
  }

  .card {
    border-radius: 8px !important;
  }

  .bg-gradient-info {
    background: linear-gradient(87deg, #11cdef, #1171ef) !important;
  }

  /*.btn-neutral {
  color: #fc328a;
}*/
  span.input-group-text i {
    color: #adb5bd;
  }

  .testimon {
    background: rgba(0, 0, 0, 0) url("https://kwm-user-data.s3.eu-west-2.amazonaws.com/kwm_web/bg5.jpg");
    padding: 120px 0;
  }

  .section-title h3 {
    color: #fff;
  }

  .overlay {
    position: relative;
  }

  .testimon h3 {
    color: #fff;
  }

  .testimonial-item p {
    color: #fff;
  }

  .testimonial-item h4 {
    font-size: 20px;
    color: #fc328a;
    margin-bottom: 10px;
    font-weight: bold;
  }

  .slick-list {
    overflow: hidden;
    margin: 0px 0px !important;
    padding: 0;
  }

  .slick-prev i.lni.lni-chevron-right {
    font-size: 52px;
    color: #fc328a;
  }

  .slick-arrow.slick-prev {
    z-index: 10000;
  }

  .slick-next,
  .slick-prev {
    line-height: 0;
    top: 47%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: 0;
    background: #3330;
  }

  .slick-arrow.slick-prev .lni.lni-chevron-left {
    font-size: 42px;
    color: #fc328a;
    font-weight: 900;
  }

  .slick-prev {
    left: 93px;
  }

  .slick-next:before,
  .slick-prev:before {
    font-size: 53px;
    line-height: 1;
    opacity: 1;
    color: #d7d7d7;
  }

  .slick-next,
  .slick-prev {
    font-size: 52px;
  }

  .custom_slid .slick-next:before {
    display: none !important;
  }

  .custom_slid .slick-prev:before {
    display: none !important;
  }

  // .slick-next:before, .slick-prev:before {
  //   display: block !important;
  //  }
  // .slick-dots, .slick-next, .slick-prev{
  //   position: relative !important;
  //   display: none !important;
  // }
  .card-title {
    margin-bottom: 0.4rem;
  }

  h5,
  .h5 {
    font-size: 1.25rem;
  }

  .header .navbar .navbar-nav .nav-item.active .nav-link {
    background: #fc328a;
    color: #fff;
  }

  .header .navbar .navbar-nav .nav-item.active .nav-link:before {
    color: #fff;
    text-shadow: 10px 0 #fff, -10px 0 #fff;
  }

  .header .navbar .navbar-nav .nav-item .icon-menu ul li {
    display: inline-block;
    margin: 0 5px;
    line-height: 40px;
  }

  .header .navbar .navbar-nav .nav-item .icon-menu ul li a i {
    font-size: 16px;
  }

  .header .navbar .navbar-nav .dropdown-menu {
    border-radius: inherit;
    background: rgba(0, 0, 0, 0.7);
  }

  .header .navbar .navbar-nav .dropdown-menu a {
    color: #fff;
    padding: 4px 20px;
    font-size: 14px;
  }

  .header .navbar .navbar-nav .dropdown-menu a:hover {
    color: #fc328a;
    background: transparent;
  }

  /*icon-menu*/
  .search-popup {
    position: fixed;
    left: 0px;
    top: -100%;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 9999;
    visibility: hidden;
    opacity: 0;
    overflow: auto;
    transition: all 700ms ease;
    -moz-transition: all 700ms ease;
    -webkit-transition: all 700ms ease;
    -ms-transition: all 700ms ease;
    -o-transition: all 700ms ease;
  }

  .search-popup.popup-visible {
    top: 0;
    visibility: visible;
    opacity: 1;
  }

  .search-popup .close-search {
    position: absolute;
    right: 25px;
    top: 25px;
    font-size: 25px;
    color: #ffffff;
    padding: 10px;
    background: #fc328a;
    cursor: pointer;
    z-index: 5;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }

  .search-popup .close-search:hover {
    opacity: 0.7;
  }

  .search-popup .search-form {
    position: relative;
    padding: 0px 15px 50px;
    padding-top: 200px;
    max-width: 700px;
    margin: 0 auto;
  }

  .search-popup .search-form fieldset {
    position: relative;
    border-radius: 12px;
  }

  .search-popup .search-form fieldset input[type="search"],
  .search-popup .search-form fieldset input.form-control {
    position: relative;
    height: 70px;
    padding: 20px 220px 20px 30px;
    background: #ffffff;
    line-height: 30px;
    font-size: 24px;
    color: #233145 !important;
  }

  .search-popup .search-form fieldset input[type="submit"] {
    position: absolute;
    display: block;
    right: 0px;
    top: 0px;
    text-align: center;
    width: 220px;
    padding: 20px 10px 20px 10px;
    color: #ffffff;
    cursor: pointer;
    background: #fc328a;
    line-height: 30px;
    height: 70px;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    font-size: 20px;
    text-transform: uppercase;
    border-radius: 0px 7px 7px 0px;
    border: none;
  }

  .search-popup .search-form fieldset input[type="submit"]:hover {
    background: #fc328a;
    color: #ffffff;
  }

  /*icon-menu*/
  /*02	Navbar Styles*/
  /*3.2 slider area start*/
  .slider-area1 .tp-caption.slide-text-one h1,
  .slider-area1 .tp-caption.slide-text-one h2,
  .slider-area1 .tp-caption.slide-text-one h3,
  .slider-area1 .tp-caption.slide-text-one h4,
  .slider-area1 .tp-caption.slide-text-one h5,
  .slider-area1 .tp-caption.slide-text-one h6 {
    font-size: 65px !important;
    line-height: 60px !important;
  }

  .slider-area1 .tp-caption.slide-text-one h1 span,
  .slider-area1 .tp-caption.slide-text-one h2 span,
  .slider-area1 .tp-caption.slide-text-one h3 span,
  .slider-area1 .tp-caption.slide-text-one h4 span,
  .slider-area1 .tp-caption.slide-text-one h5 span,
  .slider-area1 .tp-caption.slide-text-one h6 span {
    font-size: 65px !important;
    color: #fc328a;
  }

  .slider-area1 .tp-caption.slide-text-two h1,
  .slider-area1 .tp-caption.slide-text-two h2,
  .slider-area1 .tp-caption.slide-text-two h3,
  .slider-area1 .tp-caption.slide-text-two h4,
  .slider-area1 .tp-caption.slide-text-two h5,
  .slider-area1 .tp-caption.slide-text-two h6 {
    font-size: 28px !important;
  }

  .slider-area1 .tp-caption.rev-btn {
    background: #fff;
    color: #171717 !important;
    border-radius: 0px !important;
    padding: 20px 22px !important;
    transition: all 0.5s ease 0s !important;
  }

  .slider-area1 .tp-caption.rev-btn:hover {
    background: #fc328a !important;
    color: #fff !important;
  }

  .slider-area1 .tp-leftarrow.tparrows.metis {
    background: transparent;
  }

  .slider-area1 .tp-rightarrow.metis.tparrows {
    background: transparent;
  }

  .slider-area1 .metis.tparrows:before {
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    font-size: 40px;
    font-weight: 700;
    color: #fc328a;
  }

  /*3.2 slider area end*/
  /*3.3 features area start*/
  .features-area .features-body {
    position: relative;
    background: #fff;
    transform: translateY(-59px);
    display: flex;
    box-shadow: 0px 0px 13px #1717172e;
    border: 1px solid #e5e5e5;
  }

  .features-area .features-body .features-box {
    background: #fff;
    display: flex;
    flex-direction: column;
  }

  .features-area .features-body .features-box:nth-child(even) .features-elements {
    order: 1;
  }

  .features-area .features-body .features-box:nth-child(even) .features-box-img {
    order: 0;
  }

  .features-area .features-body .features-box .features-elements {
    padding: 37px 25px;
    text-align: left;
  }

  .features-area .features-body .features-box .features-elements a {
    display: block;
    text-align: left;
    margin-bottom: 9px;
  }

  .features-area .features-body .features-box .features-elements a i:before {
    font-size: 70px;
    color: #6b6b6b;
    transition: all 0.5s ease 0s;
    margin-left: 0;
  }

  .features-area .features-body .features-box .features-elements h4 {
    font-size: 25px;
    color: #171717;
  }

  .features-area .features-body .features-box a {
    display: block;
  }

  .features-area .features-body .features-box a img {
    width: 100%;
  }

  .features-area .features-body .features-box:hover a i:before {
    color: #fc328a;
  }

  /*3.3 features area end*/
  /*3.4 call to action-1 start*/
  .call-to-action1 .cta-img {
    width: 100%;
  }

  .call-to-action1 .cta-img img {
    width: 100%;
    height: 500px;
    object-fit: cover;
  }
  @media (max-width: 767px) {
    .call-to-action1 .cta-img img {
      height: 300px;
    }
  }
  .call-to-action1 .cta-box h4.sub-title {
    color: #171717;
    font-size: 32px;
    line-height: 30px;
    letter-spacing: 1px;
  }

  .call-to-action1 .cta-box h5.title {
    color: #171717;
    font-size: 35px;
    line-height: 35px;
  }

  .call-to-action1 .bttn {
    text-align: left;
  }

  .tab-action .cta-box h4 {
    font-size: 35px;
    font-weight: normal;
    color: #171717;
  }

  .tab-action .cta-box h5 {
    font-size: 35px;
    color: #fc328a;
    padding-bottom: 20px;
    border-bottom: 1px solid #a6a6a6;
  }

  .tab-action .cta-box .nav {
    flex-wrap: inherit;
    margin-top: 50px;
    border: inherit;
  }

  .tab-action .cta-box .nav .nav-item .nav-link,
  .tab-action .cta-box .nav .nav-tabs .nav-item.show .nav-link,
  .tab-action .cta-box .nav-tabs .nav .nav-item.show .nav-link,
  .single-tab-area .single-tab .nav-tabs .tab-action .cta-box .nav .nav-item.show .nav-link {
    border-radius: inherit;
  }

  .tab-action .cta-box .nav-tabs .nav-link,
  .tab-action .cta-box .nav-tabs .nav-item.show .nav-link {
    border: inherit;
    font-size: 16px;
    color: #171717;
    transition: all 0.5s ease 0s;
    padding: 10px 0;
  }

  .tab-action .cta-box .nav-tabs .nav-link i,
  .tab-action .cta-box .nav-tabs .nav-item.show .nav-link i {
    color: #fc328a;
    margin-right: 10px;
    transition: all 0.5s ease 0s;
  }

  .tab-action .cta-box .nav-tabs .nav-link:hover,
  .tab-action .cta-box .nav-tabs .nav-item.show .nav-link:hover,
  .tab-action .cta-box .nav-tabs .nav-link.active,
  .tab-action .cta-box .nav-tabs .nav-item.show .active.nav-link {
    color: #fc328a;
    background-color: transparent;
    border: inherit;
  }

  .tab-action .cta-box .nav-tabs .nav-link:hover i,
  .tab-action .cta-box .nav-tabs .nav-item.show .nav-link:hover i,
  .tab-action .cta-box .nav-tabs .nav-link.active i,
  .tab-action .cta-box .nav-tabs .nav-item.show .active.nav-link i {
    color: #171717;
  }

  .tab-action .cta-box .tab-content {
    margin-top: 15px;
  }

  .tab-action .cta-box .bttn {
    margin-top: 40px;
  }

  /*3.4 call to action-1 end*/
  /*3.5 portfolio area start*/
  /*portfolio-area1*/
  .portfolio-area .port-box {
    position: relative;
    width: 100%;
    display: inline-block;
    transform: scale(1);
    transition: all 0.5s ease 0s;
  }

  .portfolio-area .port-box .port-img {
    width: 100%;
  }

  .portfolio-area .port-box .port-img a {
    display: block;
  }

  .portfolio-area .port-box .port-img a img {
    width: 100%;
  }

  .portfolio-area .port-box .port-img a i {
    top: 40%;
    font-size: 40px;
  }

  .portfolio-area .port-box .port-dtl {
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 0%;
    opacity: 0;
    transition: all 0.5s ease 0s;
  }

  .portfolio-area .port-box .port-dtl h5 {
    color: #fff;
    font-size: 25px;
    margin-bottom: 15px;
  }

  .portfolio-area .port-box .port-dtl p {
    font-size: 15px;
    color: #fff;
    margin: 0 50px;
  }

  .portfolio-area .port-box:hover {
    transform: scale(0.9);
  }

  .portfolio-area .port-box:hover .port-dtl {
    bottom: 7%;
    opacity: 1;
  }

  .portfolio-area .port-box:hover .port-img a i {
    opacity: 1;
    top: 45%;
  }

  .portfolio-area .port-box:hover .port-img a i:hover {
    transform: scale(1.2);
  }

  .port-carousel.owl-theme .owl-controls .owl-buttons div {
    padding: 11px 21px;
    font-size: 30px;
    border-radius: 50%;
    opacity: 0.6;
    position: absolute;
    top: 45%;
    left: 1%;
  }

  .port-carousel.owl-theme .owl-controls .owl-buttons div.owl-next {
    right: 1%;
    left: auto;
  }

  .port-carousel.owl-theme .owl-controls .owl-buttons div:hover {
    opacity: 0.6;
  }

  .port-carousel.owl-theme .owl-controls .owl-buttons div i {
    color: rgba(0, 0, 0, 0.3);
    font-size: 35px;
    font-weight: 900;
  }

  /*portfolio-area1*/
  /*portfolio-area2*/
  .port-area2 {
    background: #f1f1f1;
  }

  .port-area2 .portfolio-nav ul {
    margin-top: 45px;
    float: right;
  }

  .port-area2 .portfolio-nav ul li {
    color: #6b6b6b;
    cursor: pointer;
    display: inline-block;
    padding: 5px 10px;
    font-size: 16px;
    transition: all 0.5s ease 0s;
  }

  .port-area2 .portfolio-nav ul li:hover {
    color: #fc328a;
  }

  .port-area2 .portfolio .portfolio-masonry-item {
    width: 23%;
    margin: 0 15px;
  }

  /*3.5 portfolio area end*/
  /*3.6 pricing area start*/
  .pricing-area .price-box {
    position: relative;
    border: 1px solid #e6e6e6;
    background-color: #f7f7f7;
    padding-bottom: 15px;
    border-radius: 10px;
    transition: all 0.5s ease 0s;
  }

  .pricing-area .price-box .price-empty {
    padding: 20px 20px;
    position: relative;
    border-radius: 10px;
  }

  .surahi li {
    text-align: left;
  }

  .pricing-area .price-box .price-quantity {
    padding: 20px;
    padding-top: 0;
    position: relative;
  }

  .pricing-area .price-box .price-quantity .qnty-box {
    background-color: #f7f7f7;
    position: absolute;
    width: 200px;
    height: 200px;
    line-height: 235px;
    border-radius: 50%;
    border: 4px solid #fff;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: translateY(-200px);
    transition: all 0.5s ease 0s;
  }

  .pricing-area .price-box .price-quantity .qnty-box .box-element {
    display: inline-block;
  }

  .pricing-area .price-box .price-quantity .qnty-box .box-element h5 {
    font-size: 35px;
    margin-bottom: 10px;
    color: #fc328a;
  }

  .pricing-area .price-box .price-quantity .qnty-box .box-element p {
    color: #171717;
    font-size: 15px;
    line-height: 15px;
  }

  .pricing-area .price-box .price-quantity .price-dtl ul {
    margin-bottom: 30px;
  }

  .card-img,
  .card-img-top {
    border-radius: 0px !important;
  }

  small,
  .small {
    font-weight: 400;
    font-size: 18px;
  }

  .header .nav-item .active {
    background-color: transparent;
    color: #764979 !important;
  }

  .copyright.text-center.text-xl-left.text-muted {
    background: #fff;
  }

  .font-weight-600 {
    font-weight: 600 !important;
    color: #525f7f;
    font-size: 16px;
  }

  li.d-flex.flex-column.flex-fill.my-2 .mb-0 {
    color: #525f7f;
    font-size: 16px;
  }

  .d-flex.align-items-start.pb-4.mb-4.border-bottom .lni {
    color: #525f7f;
  }

  .d-flex.align-items-start .lni {
    color: #525f7f;
  }

  .flex-fill.ml-3 p {
    font-weight: 300;
    line-height: 1.7;
    font-size: 1rem;
    color: #525f7f;
  }

  .row.g-0.border.rounded.overflow-hidden.flex-md-row.mb-4.shadow-sm.h-md-250.position-relative {
    margin-left: 12px;
  }

  nav.justify-content-center-2 {
    display: table;
    margin: 0 auto;
  }

  .page-item .page-link,
  .page-item span {
    width: 45px;
    height: 45px;
  }

  .navbar-brand {
    margin-right: 2rem;
  }

  .pricing-area .price-box .price-quantity .price-dtl ul li {
    color: #171717;
    font-size: 16px;
    padding: 12px 0;
    border-top: 1px solid #cccccc;
  }

  .pricing-area .price-box .price-quantity .price-dtl ul li.first-child {
    border: inherit;
  }

  .pricing-area .price-box .price-quantity .price-dtl .bttn .btn {
    color: #747474;
    border-radius: 5px;
    border: 1px solid #cccccc;
    padding: 7px 25px;
    font-weight: normal;
  }

  .pricing-area .price-box.active,
  .pricing-area .price-box:hover {
    // background-color: #fc328a !important;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    transform: translateY(-10px);
  }

  .pricing-area .price-box.active .qnty-box,
  .pricing-area .price-box:hover .qnty-box {
    background-color: #fc328a !important;
  }

  .pricing-area .price-box.active .price-quantity .qnty-box .box-element h5,
  .pricing-area .price-box:hover .price-quantity .qnty-box .box-element h5 {
    color: #fff;
  }

  .pricing-area .price-box.active .price-quantity .price-dtl .bttn .btn,
  .pricing-area .price-box:hover .price-quantity .price-dtl .bttn .btn,
  .pricing-area .price-box:hover .pricing-button .btn {
    border: 1px solid transparent;
  }

  .pricing-button .btn {
    border-radius: 5px;
    padding: 7px 25px;
    font-weight: normal;
    background: #fc328a;
    color: #fff !important;
    box-shadow: inherit;
    border: 1px solid transparent !important;
    text-transform: uppercase;
  }

  /*3.6 pricing area end*/
  /*3.7 schedule area*/
  .schedule-area .schdl-tab-area {
    width: 13%;
    float: left;
    display: inline-block;
  }

  .schedule-area .schdl-tab-area li a {
    font-size: 18px;
    font-weight: 700;
    background: transparent;
    border-radius: inherit;
    color: #171717;
    padding: 13px 18px;
  }

  .schedule-area .schdl-tab-area li a:hover {
    background: #fc328a;
    color: #fff;
  }

  .schedule-area .tab-content {
    width: 87%;
    float: left;
  }

  .schedule-area .tab-content .tab-pane {
    margin-left: 10px;
  }

  .schedule-area .tab-content .tab-pane.active {
    background: transparent;
  }

  .schedule-area .tab-content .tab-pane .schdl-box {
    width: 165px;
    margin: 0 12px;
    float: left;
    font-weight: bold;
    padding: 45px 10px;
    text-align: center;
    border-radius: 8px;
    border: 1px solid #6b6b6b;
    margin-bottom: 20px;
    transition: all 0.5s ease 0s;
  }

  .schedule-area .tab-content .tab-pane .schdl-box:hover {
    background: #fc328a;
    border: 1px solid transparent;
  }

  .schedule-area .tab-content .tab-pane .schdl-box:hover h5 {
    color: white;
  }

  .schedule-area .tab-content .tab-pane .schdl-box:hover p {
    color: #fff;
  }

  .schedule-area .tab-content .tab-pane .schdl-box h5 {
    font-size: 20px;
    color: #171717;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .schedule-area .tab-content .tab-pane .schdl-box p {
    word-spacing: 0;
  }

  .schedule-area .dwnload {
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }

  .content-carousel {
    max-width: 900px;
    margin: 0 auto;
    position: relative;
    z-index: 1;

    .slick-controls {
      position: absolute;
      z-index: 1;
      top: 53px;
      left: 60px;
    }

    .content-carousel-card {
      .card-content-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        z-index: 1;
        padding: 0 60px;

        &:before {
          content: "";
          display: block;
          position: absolute;
          z-index: 1;
          width: calc(100% - 2px);
          height: calc(100% - 50px);
          top: 50%;
          transform: translateY(-50%);
          border: 1px solid #000;
          left: 0px;
          border-radius: 50px;
        }

        .text-box {
          padding-right: 50px;
        }
      }
    }
  }

  .text-box.p-5 h1 {
    transition: none 0s ease 0s;
    text-align: inherit;
    line-height: 40px;
    border-width: 0px;
    margin: 0px;
    padding: 0px;
    letter-spacing: 0px;
    font-weight: 700;
    font-size: 65px !important;
    color: #fc328a;
    text-transform: uppercase;
  }

  .text-box.p-5 h3 {
    transition: none 0s ease 0s;
    text-align: inherit;
    line-height: 82px;
    border-width: 0px;
    margin: 0px;
    padding: 0px;
    letter-spacing: 0px;
    font-weight: 700;
    font-size: 28px !important;
    color: #fff;
    text-transform: uppercase;
  }

  .text-box.p-lg h1 {
    transition: none 0s ease 0s;
    text-align: inherit;
    line-height: 70px;
    border-width: 0px;
    margin: 0px;
    padding: 0px;
    letter-spacing: 0px;
    font-weight: 700;
    font-size: 65px !important;
    color: #fc328a;
    text-transform: uppercase;
  }

  .text-box.p-lg h3 {
    transition: none 0s ease 0s;
    text-align: inherit;
    line-height: 82px;
    border-width: 0px;
    margin: 0px;
    padding: 0px;
    letter-spacing: 0px;
    font-weight: 700;
    font-size: 28px !important;
    color: #fff;
    text-transform: uppercase;
  }

  .test-img-2 img {
    margin-right: 10px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }

  .test-img-2 {
    float: left;
    display: inline-block;
    width: 13%;
  }

  .services-img img {
    width: 100%;
  }

  .testimonial-item {
    padding: 40px 80px;
    display: inline-block;
    position: relative;
  }

  .section-title.text-center {
    text-align: center !important;
  }

  .text-box.p-5 {
    position: absolute;
    overflow: hidden;
    margin: 400px 200px;
  }

  .text-box.p-lg {
    position: absolute;
    overflow: hidden;
    // padding: 200px 350px 200px 150px;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.466);
  }

  .schedule-area .dwnload a {
    font-size: 18px;
    color: #171717;
  }

  .schedule-area .dwnload a span {
    font-size: 20px;
    margin-right: 15px;
    transition: all 0.5s ease 0s;
  }

  .schedule-area .dwnload a span i {
    color: #fc328a;
    transition: all 0.5s ease 0s;
  }

  .schedule-area .dwnload a:hover {
    color: #fc328a;
  }

  .schedule-area .dwnload a:hover span i {
    transform: translateY(2px);
  }

  .schedule-area .nav-tabs .nav-link,
  .schedule-area .tab-action .cta-box .nav-tabs .nav-item.show .nav-link,
  .tab-action .cta-box .schedule-area .nav-tabs .nav-item.show .nav-link,
  .schedule-area .nav-tabs .nav-item.show .nav-link,
  .schedule-area .single-tab-area .single-tab .nav-tabs .nav-item.show .nav-link,
  .single-tab-area .single-tab .schedule-area .nav-tabs .nav-item.show .nav-link {
    border: inherit;
  }

  .schedule-area .nav-tabs .nav-link:hover,
  .schedule-area .nav-tabs .nav-item.show .nav-link:hover,
  .schedule-area .nav-tabs .nav-link.active,
  .schedule-area .nav-tabs .nav-item.show .active.nav-link {
    color: #fff;
    background-color: #fc328a;
    border: inherit;
  }

  .nav-link:focus,
  .tab-action .cta-box .nav-tabs .nav-item.show .nav-link:focus,
  .schedule-area .nav-tabs .nav-item.show .nav-link:focus,
  .schedule-area2 .nav-tabs .nav-item.show .nav-link:focus,
  .single-tab-area .single-tab .nav-tabs .nav-item.show .nav-link:focus,
  .nav-link:hover,
  .tab-action .cta-box .nav-tabs .nav-item.show .nav-link:hover,
  .schedule-area .nav-tabs .nav-item.show .nav-link:hover,
  .schedule-area2 .nav-tabs .nav-item.show .nav-link:hover,
  .single-tab-area .single-tab .nav-tabs .nav-item.show .nav-link:hover {
    border-color: transparent;
  }

  /*schedule-area2*/
  .schedule-area2 .schdl-tab-area2.nav {
    flex-wrap: inherit;
  }

  .schedule-area2 .schdl-tab-area2.nav .nav-item {
    text-align: center;
    border-radius: inherit;
    background: #f1f1f1;
  }

  .schedule-area2 .schdl-tab-area2.nav .nav-item .nav-link,
  .tab-action .cta-box .nav-tabs .schedule-area2 .schdl-tab-area2.nav .nav-item.show .nav-link,
  .schedule-area2 .schdl-tab-area2.nav .single-tab-area .single-tab .nav-tabs .nav-item.show .nav-link,
  .single-tab-area .single-tab .nav-tabs .schedule-area2 .schdl-tab-area2.nav .nav-item.show .nav-link {
    color: #fff;
    padding: 30px 0px;
    font-size: 25px;
    color: #171717;
    border-radius: inherit;
  }

  .schedule-area2 .schdl-tab-area2.nav .nav-item .nav-link span,
  .tab-action .cta-box .nav-tabs .schedule-area2 .schdl-tab-area2.nav .nav-item.show .nav-link span,
  .schedule-area2 .schdl-tab-area2.nav .single-tab-area .single-tab .nav-tabs .nav-item.show .nav-link span,
  .single-tab-area .single-tab .nav-tabs .schedule-area2 .schdl-tab-area2.nav .nav-item.show .nav-link span {
    margin-top: 10px;
    width: 100%;
    display: block;
    font-size: 14px;
    color: #6b6b6b;
    font-weight: normal;
  }

  .schedule-area2 .nav-tabs .nav-link,
  .schedule-area2 .tab-action .cta-box .nav-tabs .nav-item.show .nav-link,
  .tab-action .cta-box .schedule-area2 .nav-tabs .nav-item.show .nav-link,
  .schedule-area2 .nav-tabs .nav-item.show .nav-link,
  .schedule-area2 .single-tab-area .single-tab .nav-tabs .nav-item.show .nav-link,
  .single-tab-area .single-tab .schedule-area2 .nav-tabs .nav-item.show .nav-link {
    border: inherit;
  }

  .schedule-area2 .nav-tabs .nav-link:hover,
  .schedule-area2 .nav-tabs .nav-item.show .nav-link:hover,
  .schedule-area2 .nav-tabs .nav-link.active,
  .schedule-area2 .nav-tabs .nav-item.show .active.nav-link {
    color: #fff;
    background-color: #fc328a;
    border: inherit;
  }

  .schedule-area2 .nav-tabs .nav-link:hover span,
  .schedule-area2 .nav-tabs .nav-item.show .nav-link:hover span,
  .schedule-area2 .nav-tabs .nav-link.active span,
  .schedule-area2 .nav-tabs .nav-item.show .active.nav-link span {
    color: #fff !important;
  }

  .schedule-area2 .schedule-tab-content {
    margin-top: 60px;
    display: inline-block;
  }

  .schedule-area2 .schedule-tab-content .tab-pane ul li {
    width: 100%;
    float: left;
    padding: 30px 0;
    border-top: 1px solid #e1e1e1;
  }

  .schedule-area2 .schedule-tab-content .tab-pane ul li .schedule-img {
    width: 20%;
    float: left;
  }

  .schedule-area2 .schedule-tab-content .tab-pane ul li .schedule-img img {
    width: 100%;
  }

  .schedule-area2 .schedule-tab-content .tab-pane ul li .schedule-desk {
    padding: 43px 0;
    width: 70%;
    float: left;
    margin-left: 35px;
    display: inline-block;
  }

  .schedule-area2 .schedule-tab-content .tab-pane ul li .schedule-desk h5 {
    color: #6b6b6b;
    font-size: 15px;
  }

  .schedule-area2 .schedule-tab-content .tab-pane ul li .schedule-desk h3 {
    color: #171717;
    font-size: 22px;
    margin-top: 15px;
  }

  .schedule-area2 .schedule-tab-content .tab-pane ul li .schedule-desk a {
    float: right;
    margin-top: -35px;
  }

  .schedule-area2 .schedule-tab-content .tab-pane ul li .schedule-desk a i {
    color: #6b6b6b;
    font-size: 40px;
    transition: all 0.5s ease 0s;
  }

  .schedule-area2 .schedule-tab-content .tab-pane ul li .schedule-desk a i:hover {
    color: #fc328a;
  }

  .schedule-area2 .schedule-tab-content .tab-pane ul li:hover .schedule-desk h3 {
    color: #fc328a;
  }

  .schedule-area2 .schedule-tab-content .tab-pane ul li:last-child {
    padding-bottom: 0;
  }

  .schedule-area2 .schedule-tab-content .tab-pane ul li:first-child {
    padding-top: 0;
    border-top: inherit;
  }

  /*3.7 schedule area*/
  /*3.8 product box*/
  .product-area .product-carousel {
    position: relative;
  }

  .product-area .product-carousel .owl-buttons div {
    position: absolute;
    left: -6%;
    top: 40%;
    background: transparent !important;
    font-size: 40px;
  }

  .product-area .product-carousel .owl-buttons div i {
    color: #a6a6a6;
  }

  .product-area .product-carousel .owl-next {
    right: -6%;
    left: inherit !important;
  }

  .product-area .product-box {
    position: relative;
    display: block;
  }

  .product-area .product-box .cart-box {
    position: relative;
  }

  .product-area .product-box .cart-box .cart-img a {
    width: 100%;
    display: block;
  }

  .product-area .product-box .cart-box .cart-img a img {
    width: 100%;
  }

  .product-area .product-box .cart-box .cart-element {
    position: absolute;
    bottom: 20%;
    display: block;
    width: 100%;
    text-align: center;
    opacity: 0;
  }

  .product-area .product-box .cart-box .cart-element a {
    background: #fff;
    padding: 6px 17px;
    border-radius: 20px;
    font-size: 12px;
    color: #171717;
    margin-right: 10px;
    transition: all 0.5s ease 0s;
  }

  .product-area .product-box .cart-box .cart-element i {
    color: #fff;
    margin-left: 10px;
    transition: all 0.5s ease 0s;
  }

  .product-area .product-box .cart-box:hover a .ovrlay {
    opacity: 1;
  }

  .product-area .product-box .cart-box:hover .cart-element {
    opacity: 1;
  }

  .product-area .product-box .cart-box:hover .cart-element a {
    margin-right: 5px;
  }

  .product-area .product-box .cart-box:hover .cart-element a:hover {
    background: #fc328a;
  }

  .product-area .product-box .cart-box:hover .cart-element i {
    margin-left: 5px;
  }

  .product-area .product-box .cart-dtl {
    padding: 20px 42px 0 42px;
  }

  .product-area .product-box .cart-dtl h4 {
    font-size: 18px;
    color: #171717;
    margin-bottom: 10px;
  }

  .product-area .product-box .cart-dtl h4 span {
    float: right;
  }

  .product-area .product-box .cart-dtl .add-cart {
    width: 100%;
    display: block;
    text-align: center;
    line-height: 14px;
  }

  .product-area .product-box .cart-dtl .add-cart a {
    font-size: 14px;
    color: #6b6b6b;
  }

  .product-area .product-box:hover .cart-dtl .add-cart a {
    color: #171717;
    background: inherit;
  }

  /*3.8 product box*/
  /*3.9 call to action*/
  .call-to-action {
    background-color: #ffffff;
  }

  .call-to-action h4 {
    text-transform: capitalize;
    color: #171717;
    font-size: 36px;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: normal;
    text-align: left;
    margin-top: 5px;
  }

  .call-to-action .bttn {
    text-align: right;
  }

  /*3.9 call to action*/
  /*4.1 blog area start*/
  .blog-box {
    border: 1px solid #ececec;
    display: inline-block;
    position: relative;
    transition: all 0.5s ease 0s;
  }

  .blog-box .blog-img {
    position: relative;
  }

  .blog-box .blog-img img {
    position: relative;
    width: 100%;
  }

  .blog-box .blog-date {
    background: #fff;
    display: block;
    height: 60px;
    left: 6%;
    position: absolute;
    top: 30%;
    width: 54px;
    text-transform: uppercase;
  }

  .blog-box .blog-date span {
    display: block;
    text-align: center;
    color: #fc328a;
  }

  .blog-box .blog-date span.date {
    font-size: 20px;
    margin-top: 5px;
  }

  .blog-box .blog-content {
    padding: 5px 20px 10px;
    display: inline-block;
  }

  .blog-title p {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    color: #fc328a;
    margin-bottom: 0.5rem;
  }

  .blog-box .blog-content .blog-title a {
    color: #171717;
    font-size: 18px;
    transition: all 0.5s ease 0s;
  }

  .blog-box .blog-content .meta {
    margin-top: 12px;
    display: inline-block;
  }

  .blog-box .blog-content .meta .news-author {
    float: left;
    margin-right: 5px;
  }

  .blog-box .blog-content .meta .news-author i {
    font-size: 15px;
    margin-right: 3px;
    color: #fc328a;
  }

  .blog-box .blog-content .meta .news-author a {
    color: #6b6b6b;
    font-size: 15px;
  }

  .blog-box .blog-content .meta .news-author:hover a {
    color: #fc328a;
  }

  .blog-box .blog-content .meta .coment-counter {
    float: left;
  }

  .blog-box .blog-content .meta .coment-counter span {
    font-size: 15px;
    color: #fc328a;
  }

  .blog-box .blog-content .news-introtxt {
    margin-top: 15px;
    float: left;
  }

  .blog-box .blog-content .blog-footer {
    float: left;
  }

  .blog-box .blog-content .blog-footer a {
    color: #707d82;
    display: inline-block;
    font-size: 14px;
    margin-top: 10px;
    transition: all 0.5s ease 0s;
  }

  .blog-box:hover {
    box-shadow: 2px 4px 43px -8px #171717;
  }

  .blog-box:hover .blog-title a {
    color: #fc328a;
  }

  .blog-box:hover .blog-footer a {
    color: #fc328a;
  }

  .blog-page .blog-date {
    top: 0;
    left: 0;
    background: #fff;
    padding: 6px;
    width: 80px;
    height: 80px;
    line-height: 1.8;
  }

  .blog-page .blog-date .date-box {
    background: #fc328a;
    display: inline-block;
    width: 100%;
  }

  .blog-page .blog-date .date-box .date {
    color: #fff;
  }

  .blog-page .blog-date .date-box .month {
    color: #171717;
  }

  .blog-page .blog-content .meta .coment-counter {
    margin-left: 5px;
  }

  .blog-area .portfolio-nav ul li {
    padding: 10px 15px;
    font-size: 18px;
    color: #6b6b6b;
    margin: 15px;
    transition: all 0.5s ease 0s;
    border-radius: 8px;
    display: inline-block;
    cursor: pointer;
  }

  .blog-area .portfolio-nav ul li.active,
  .blog-area .portfolio-nav ul li:hover {
    background: #fc328a;
    color: #fff;
  }

  .blog-area .blog-portfolio .portfolio-masonry-item {
    margin: 0px 15px;
  }

  .blog-area .blog-portfolio .portfolio-masonry-item .audio-box audio {
    width: 100%;
  }

  .blog-area .blog-portfolio .portfolio-masonry-item .video {
    width: 100%;
    padding: 0;
    border: inherit;
  }

  .blog-area .blog-portfolio .portfolio-masonry-item .video:focus {
    border: inherit !important;
  }

  .blog-area .blog-portfolio .portfolio-masonry-item .video.btn-primary {
    border: inherit !important;
  }

  .blog-area .blog-portfolio .portfolio-masonry-item .video span {
    display: block;
    position: relative;
  }

  .blog-area .blog-portfolio .portfolio-masonry-item .video span img {
    width: 100%;
  }

  .blog-area .blog-portfolio .portfolio-masonry-item .video span:before {
    background: rgba(252, 50, 138, 0.6);
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .blog-area .blog-portfolio .portfolio-masonry-item .video span i {
    position: absolute;
    font-size: 50px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 40px;
    height: 40px;
    opacity: 0.8;
    z-index: 9;
  }

  .blog-area .blog-portfolio .portfolio-masonry-item .modal-content .modal-body {
    padding: inherit;
  }

  .blog-area .blog-portfolio .portfolio-masonry-item .modal-content .video-content video {
    width: 100%;
  }

  .blog-left-sidebar .left-sidebar .side-title h3 {
    font-size: 16px;
    font-weight: 700;
    margin-top: 0;
    color: #6b6b6b;
    margin-bottom: 15px;
  }

  .blog-left-sidebar .left-sidebar .quick-links ul {
    margin-top: -6px;
  }

  .blog-left-sidebar .left-sidebar .quick-links ul li {
    padding: 3px 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s ease 0s;
  }

  .blog-left-sidebar .left-sidebar .quick-links ul li a {
    color: #707d82;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 22px;
    text-transform: capitalize;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
  }

  .blog-left-sidebar .left-sidebar .quick-links ul li a:before {
    content: "";
    display: block;
    float: left;
    font-family: fontawesome;
    margin-right: 10px;
    transition: all 0.3s ease 0s;
  }

  .blog-left-sidebar .left-sidebar .quick-links ul li a:hover {
    color: #fc328a;
    margin-left: 5px;
  }

  .pagination {
    border: 1px solid transparent;
  }

  .pagination .page-item {
    margin: 0 5px;
  }

  .pagination .page-item .page-link {
    padding: 10px 15px;
    border: 2px solid #fc328a;
    border-radius: inherit;
    color: #6b6b6b;
    font-size: 18px;
    border-radius: 0% !important;
  }

  .page-item.active .page-link {
    color: #fff;
    background-color: #fc328a;
  }

  .slick-dots,
  .slick-next,
  .slick-prev {
    display: block !important;
  }

  .slick-next {
    right: 93px;
  }

  .slick-next,
  .slick-prev {
    line-height: 0;
    top: 47%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: 0;
    background: #3330;
  }

  .slick-next i.lni.lni-chevron-right {
    font-size: 42px;
    color: #fc328a;
    font-weight: 900;
  }

  .table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    color: #525f7f;
    background-color: transparent;
  }

  .table td,
  .table th {
    white-space: initial;
  }

  .py-4.card-footer .lni {
    color: #333;
  }

  .pagination .page-item:hover .page-link {
    background: #fc328a;
    color: #fff;
  }

  /*4.1 blog area end*/
  /*4.2 client area */
  .client {
    overflow: hidden;
  }

  .client .item {
    margin: inherit;
    opacity: 0.57;
    transition: all 0.3s ease 0s;
    width: 70%;
  }

  .client .item img {
    width: 100%;
    filter: grayscale(100%);
  }

  .client .item img:hover {
    color: #fc328a;
    filter: grayscale(0%);
  }

  .client .item:hover {
    opacity: 1;
    transform: scale(1.2);
  }

  /*4.3 client area*/
  .testimonial-area .testimonial-box {
    padding: 40px 60px;
    display: inline-block;
    position: relative;
  }

  .testimonial-area .testimonial-box .test-img {
    float: left;
    display: inline-block;
    width: 13%;
  }

  .testimonial-area .testimonial-box .test-img img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }

  .testimonial-area .testimonial-box .test-content {
    overflow: hidden;
    display: inline-block;
    float: left;
    width: 82%;
  }

  .testimonial-area .testimonial-box .test-content .person {
    margin-bottom: 30px;
  }

  .testimonial-area .testimonial-box .test-content .person h4 {
    font-size: 20px;
    color: #fc328a;
    margin-bottom: 10px;
    font-weight: bold;
  }

  .testimonial-area .testimonial-box .test-content .person h5 {
    font-size: 14px;
    color: #6b6b6b;
  }

  .testimonial-area .testimonial-box .test-content p {
    color: #ddd;
  }

  .testimonial-area .testimonial-box:before {
    content: "\f10e";
    position: absolute;
    font-family: fontawesome;
    right: 13px;
    font-size: 60px;
    transform: rotateX(200deg);
    color: #fc328a;
  }

  .testimonial-area .owl-theme .owl-controls.clickable .owl-page:hover span {
    background: #fc328a;
    transform: scale(1.5);
    transition: all 0.5s ease 0s;
  }

  .testimonial-area .owl-theme .owl-controls .owl-page.active span {
    background: #fc328a;
    transform: scale(1.5);
    transition: all 0.5s ease 0s;
  }

  /*4.4 feature box + call to action*/
  .feature-box {
    transform: translateY(-80px);
  }

  .feature-box .features-body {
    padding: 40px 0;
    transition: all 0.5s ease 0s;
    box-shadow: 1px 5px 22px -1px #171717;
    transform: inherit;
  }

  .feature-box .features-body .features-box {
    width: 100%;
    float: left;
    background: #fff;
  }

  .feature-box .features-body .features-box .features-elements h4 {
    margin-top: 12px;
  }

  .feature-box .features-body .features-box .features-elements img {
    height: 70px;
  }

  .feature-box .features-body .features-box .features-elements .bttn {
    margin-top: 24px;
  }

  .feature-box .features-body .features-box .features-elements .bttn .btn {
    border: 1px solid;
    padding: 6px 19px;
    font-size: 14px;
    color: #6b6b6b;
    font-weight: normal;
    border-radius: 5px;
  }

  .feature-box .features-body:hover {
    transform: translateY(-40px);
  }

  .call-to-action.action-box {
    background: inherit;
  }

  .call-to-action.action-box .bttn .btn {
    background: transparent;
    border: 1px solid;
    color: #fff;
  }

  .call-to-action.action-box .bttn :hover {
    background: #fff;
    color: #171717 !important;
  }

  /*4.6 about us*/
  .about-area .aboutus-box .about-benefit a {
    color: #fc328a;
    float: left;
    font-size: 15px;
    float: left;
    margin-right: 5px;
  }

  .about-area .aboutus-box .about-benefit a span {
    margin-right: 5px;
  }

  .about-area .aboutus-box .about-benefit a span i {
    color: #fc328a;
  }

  .about-area .about-opening {}

  .section-title.text-center {
    text-align: center !important;
  }

  .more-about {
    font-size: 18px;
    font-weight: 700;
  }

  .about-area .about-opening .opening-hours h3 {
    color: #fc328a;
    font-size: 20px;
  }

  .about-area .about-opening .opening-hours ul {
    display: inline-block;
    margin-top: 20px;
    width: 100%;
  }

  .about-area .about-opening .opening-hours ul li {
    color: #6b6b6b;
    font-size: 13px;
    padding: 10px 0;
    border-bottom: 1px solid #a6a6a6;
  }

  .about-area .about-opening .opening-hours ul li span {
    float: right;
  }

  .about-area .about-opening .opening-img {
    position: relative;
    margin-top: 40px;
    width: 100%;
    display: inlin-block;
  }

  .about-area .about-opening .opening-img img {
    width: 100%;
  }

  .about-area .about-opening .opening-img .about-nd {
    position: absolute;
    top: 36%;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
  }

  .about-area .about-opening .opening-img .about-nd h5 {
    margin-bottom: 5px;
    font-size: 20px;
  }

  .about-area .about-opening .opening-img .about-nd p {
    color: #fff;
  }

  /*4.6.1 about-trainers*/
  .trainers-area .trainer-pro .trainer-img {
    position: relative;
    margin-bottom: 40px;
  }

  .trainers-area .trainer-pro .trainer-img:before {
    background: rgba(252, 50, 138, 0.8);
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: scale(0);
    transition: all 0.5s ease 0s;
    z-index: 9;
    border-radius: 5%;
  }

  .trainers-area .trainer-pro .trainer-img:hover:before {
    transform: scale(1);
  }

  .trainers-area .trainer-pro .trainer-img img {
    width: 100%;
    border-radius: 5%;
  }

  .call-to-action1.tab-action.plan-box {
    padding: 60px 0px;
  }

  .able {
    color: #333;
  }

  .trainers-area .trainer-pro .trainer-img ul {
    position: absolute;
    display: inline-block;
    top: 49%;
    left: 12%;
    z-index: 9;
    opacity: 0;
    transition: all 0.5s ease 0.2s;
  }

  .trainers-area .trainer-pro .trainer-img ul li {
    display: inline-block;
    margin: 0 3px;
    transform: perspective(50px) rotatey(80deg);
    transition: all 0.5s ease 0.2s;
  }

  .trainers-area .trainer-pro .trainer-img ul li i {
    color: rgba(252, 50, 138, 0.8);
    font-size: 20px;
    padding: 5px;
    background: #fff;
    transition: all 0.5s ease 0s;
    width: 28px;
    height: 28px;
    line-height: 21px;
    text-align: center;
  }

  .trainers-area .trainer-pro .trainer-img ul li i:hover {
    background: #e20d69;
    color: rgba(0, 0, 0, 0.5);
  }

  .trainers-area .trainer-pro .trainer-img:hover ul {
    left: 20%;
    opacity: 1;
  }

  .trainers-area .trainer-pro .trainer-img:hover ul li {
    transform: perspective(50px) rotatey(0deg);
  }

  .trainers-area .trainer-pro .trainer-bio {
    text-align: center;
  }

  .trainers-area .trainer-pro .trainer-bio h4 {
    color: #171717;
    font-size: 25px;
    margin-bottom: 5px;
  }

  .trainers-area .trainer-pro .trainer-bio p {
    text-transform: capitalize;
  }

  /*colum-4*/
  .colum-4 .trainer-bio h4 {
    color: #fff !important;
  }

  /*.colum-4-2*/
  .colum-4-2 .trainer-img img {
    border-radius: 10px !important;
  }

  .colum-4-2 .trainer-img:before {
    border-radius: 10px !important;
  }

  /*expert-trainer*/
  .expert-trainer .trainer-img img {
    border-radius: 0px !important;
    border: inherit !important;
  }

  .expert-trainer .trainer-img:before {
    border-radius: 0px !important;
  }

  .expert-trainer .trainer-img ul {
    top: 80% !important;
  }

  /*4.6.2 about-service start*/
  .feature-service .features-box .feature-icon {
    display: inline-block;
    float: left;
    margin-bottom: 20px;
  }

  .feature-service .features-box .feature-icon i:before {
    color: #6b6b6b;
    font-size: 50px;
    margin-left: 0;
    transition: all 0.5s ease 0s;
  }

  .feature-service .features-box h4 {
    color: #171717;
    margin-left: 30px;
    margin-bottom: 0;
    display: inline-block;
    transition: all 0.5s ease 0s;
  }

  .feature-service .features-box p {
    display: inline-block;
  }

  .feature-service .features-box:hover .feature-icon i:before {
    color: #fc328a;
  }

  .feature-service .features-box:hover h4 {
    color: #fc328a;
  }

  /*calculate-area start*/
  .bmi-box {
    position: relative;
  }

  .bmi-box p {
    color: #fff;
  }

  .bmi-box .form-group h4 {
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: normal;
    color: #fff;
  }

  .bmi-box .form-group input {
    background: transparent;
  }

  .bmi-box .form-group input:focus,
  .bmi-box .form-group input:hover {
    border: 1px solid #fff;
  }

  .bmi-box .form-group input::placeholder {
    color: #171717;
  }

  .bmi-box .form-group select.form-control {
    height: inherit;
    background: transparent;
  }

  .bmi-box .form-group select.form-control:hover {
    border: 1px solid #fff;
  }

  .bmi-box .form-group .bttn .btn {
    background: #fff;
    color: #fc328a !important;
    border: 1px solid #fc328a !important;
  }

  .bmi-box .form-group .bttn .btn:hover {
    background: #e20d69;
    color: #fff !important;
  }

  .bmi-box .form-group p {
    color: #fff;
  }

  /*calculate-area end*/
  /*features-goal start*/
  .features-goal .features-box .goal-img {
    padding: 0 20px;
  }

  .features-goal .features-box .goal-img img {
    border: 2px solid #6b6b6b;
    border-radius: 50%;
    width: 100%;
  }

  .features-goal .features-box h4 {
    color: #171717;
    transition: all 0.5s ease 0s;
    font-size: 20px;
  }

  .features-goal .features-box:hover h4 {
    color: #fc328a;
  }

  /*features-goal end*/
  /*4.6.2 about-service end*/
  /*4.6.3 about-service-single start*/
  .single-tab-area .single-tab .nav-tabs .nav-item .nav-link,
  .single-tab-area .single-tab .nav-tabs .nav-item.show .nav-link {
    font-size: 15px;
    color: #171717;
    font-weight: bold;
    padding: 16px 35px;
    border: 1px solid #e2e2e2;
    background: #f7f7f7;
    border-radius: inherit;
  }

  .mt20 i {
    color: #fc328a;
    margin: 8px;
  }

  .service-title .mt20 {
    display: flex;
    margin-top: 4px;
  }

  .single-tab-content {
    margin-bottom: 60px;
  }

  .mt21 i {
    color: #fc328a;
  }

  /*.cta-box.pad90 p {
    margin: 8px 0px;
}*/
  .cta-box.pad90 .bttn {
    margin-top: 10px;
  }

  .single-tab-area .single-tab .nav-tabs .nav-link:hover,
  .single-tab-area .single-tab .nav-tabs .nav-item.show .nav-link:hover,
  .single-tab-area .single-tab .nav-tabs .nav-link.active,
  .single-tab-area .single-tab .nav-tabs .nav-item.show .active.nav-link {
    color: #fff;
    background-color: #fc328a;
    border: 1px solid transparent;
  }

  .single-tab-area .subscribe-box {
    padding: 24px 0px;
    background: #f7f7f7;
    margin-top: 20px;
  }

  .packages .bttn {
    text-align: left;
  }

  .video-freme.classfirst {
    margin: 26px 0px;
  }

  .single-tab-area .subscribe-box .subs-title {
    padding: 00px 30px;
    text-align: center;
    margin-bottom: 30px;
  }

  .single-tab-area .subscribe-box .subs-title h4 {
    color: #171717;
    font-size: 18px;
    margin-bottom: 10px;
  }

  .single-tab-area .subscribe-box .subscribe {
    position: relative;
  }

  .single-tab-area .subscribe-box .subscribe input {
    border: 1px solid #cccccc;
    outline: inherit;
    width: 100%;
    height: 46px;
    padding: 5px 15px;
    color: #171717;
    background: #fff;
  }

  .single-tab-area .subscribe-box .subscribe input::placeholder {
    color: #cccccc;
    font-size: 14px;
  }

  .single-tab-area .subscribe-box .subscribe a {
    font-size: 14px;
    color: #fff;
    background: #fc328a;
    padding: 12px 30px;
    position: absolute;
    top: 0;
    right: 0;
  }

  .single-tab-area .single-tab-content .services-content .services-img {
    margin-bottom: 40px;
    overflow: hidden;
  }

  .single-tab-area .single-tab-content .services-content .services-img img {
    width: 100%;
    transform: scale(1);
    transition: all 1.2s ease;
  }

  /*.single-tab-area .single-tab-content .services-content .services-img img:hover {
      transform: scale(1.3); }*/
  .single-tab-area .single-tab-content .services-content .service-title h4 {
    font-size: 18px;
    color: #333;
    margin-bottom: 4px;
    letter-spacing: 1px;
    font-weight: 400;
  }

  .single-tab-area .single-tab-content .services-content .service-title p.quote {
    font-size: 18px;
  }

  .service-box .price-box {
    position: relative;
    margin-top: 40px;
    border: 1px solid #e6e6e6;
    background-color: #f7f7f7;
    padding: 18px 18px;
    border-radius: 10px;
    transition: all 0.5s ease 0s;
  }

  .single-tab-area .single-tab-content .services-content ul {
    margin-top: 15px;
  }

  .single-tab-area .single-tab-content .services-content ul li {
    width: 90%;
    margin: 0 8px;
    color: #171717;
    font-size: 16px;
    padding: 12px 0;
    border-top: 1px solid #cccccc;
  }

  .single-tab-area .single-tab-content .services-content ul li img {
    width: 100%;
  }

  /*accordion*/
  .card {
    // background-color: rgb(255 255 255);
    border: 1px solid #eee;
    border-radius: 0;
  }

  .class-time {
    margin-left: 15px;
    margin-top: 40px;
  }

  .video-class {
    margin: 40px 0px;
  }

  .price-empty p {
    font-size: 24px;
    font-weight: 500;
    color: #111;
    line-height: 32.6px;
    text-align: left;
    margin-bottom: 0px !important;
  }

  .cta-box.pad90 {
    padding: 0px 0;
  }

  .mb300 {
    color: #fc328a;
    line-height: 49px;
    font-size: 29px;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  .mb300 span {
    text-transform: lowercase;
  }

  a.nav-link.text-uppercase span {
    text-transform: lowercase;
  }

  .cta-box.pad90.services_page .mb20 {
    font-size: 20px;
    font-weight: 600;
    margin-top: 22px;
  }

  .services_page .bttn {
    text-align: left;
    margin-bottom: 33px;
  }

  .card-text span a {
    color: #fc328a;
  }

  .home {
    .service-card:hover {
      box-shadow: 0px 2px 24px #3333333d;
      transition: box-shadow 0.3s ease-in-out;
    }
    .service-card {

      img {
        width: auto;
        height: 100%;
        border-top-left-radius: 8px !important;
        border-bottom-left-radius: 8px !important;
      }

      .card-body {
        padding: 10px;

        p {
          line-height: 1.4rem;
          margin-bottom: 0 !important;
        }

      }
    }
  }

  .card-header:first-child {
    border-radius: inherit;
  }

  .card-header {
    border: transparent;
    padding: inherit;
    background: transparent;
    padding: 1.25rem 1.5rem;
  }

  .card-header h5 {
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    display: inline-block;
    width: 100%;
  }

  .card-header a {
    color: #171717;
    font-size: 16px;
    width: 100%;
    letter-spacing: 1px;
    text-transform: initial;
    padding: 20px 0 20px 60px;
    display: block;
    background: #fff;
    font-weight: 700;
  }

  .card-header a.collapsed {
    background: #f7f7f7;
  }

  .card-body {
    color: #171717;
    outline: 0 none;
  }

  .bg-secondary .card-body {
    color: #171717;
    outline: 0 none;
    padding: 1.05em !important;
  }

  .bvkkQT {
    color: #525f7f !important;
  }

  .faq-list.nav-link {
    background: #efefef;
  }

  .faq-list.nav-link:hover {
    color: #fc328a;
  }

  .card-header a::before,
  .card-header a::before,
  .card-header a.collapsed::before {
    color: #fc328a;
    content: "\f068";
    display: block;
    font-family: fontawesome;
    font-size: 11px;
    left: 23px;
    position: absolute;
    top: 23px;
    display: none;
  }

  .video-set {
    margin-right: 15px;
  }

  .button-upper {
    font-weight: 400;
    font-size: 16px;
    margin-top: 36px;
  }

  .price-dtl.packages ul {
    margin: 30px 0px;
  }

  .card-header a.collapsed::before {
    content: "\f067";
  }

  /*accordion*/
  /*4.6.3 about-service-single end*/
  /*4.6.4 Animated Counter start*/
  .counter-area .single-counter {
    display: block;
    text-align: center;
  }

  .counter-area .single-counter span {
    display: inline-block;
  }

  .counter-area .single-counter span i::before {
    display: block;
    font-size: 70px;
    margin: 0 0 12px;
  }

  .counter-area .single-counter h3 {
    font-size: 45px;
    color: #fff;
  }

  .counter-area .single-counter p {
    font-size: 35px;
    color: #fc328a;
  }

  /*4.6.4 Animated Counter start*/
  /*4.6 -----------------
about us pages end
---------------------*/
  /*4.7  ---------------
pages start
---------------------*/
  /*4.7.1 pricing-plan start*/
  .plan-box .cta-box h5 {
    border: inherit;
    padding-bottom: 0;
  }

  .call-to-action1 .cta-box .bttn .btn {
    padding: 14px 46px;
  }

  /*4.7.1 pricing-plan end*/
  /*4.7.2 contact us start*/
  .contact-area .location {
    display: inline-block;
    width: 100%;
  }

  .contact-area .location .ctc-content {
    width: 100%;
    float: left;
  }

  .contact-area .location .ctc-content i {
    color: #fc328a;
    font-size: 28px;
    float: left;
    width: 15%;
  }

  .contact-area .location .ctc-content p {
    float: left;
  }

  .contact-area h4 {
    font-size: 25px;
    color: #171717;
    margin-bottom: 30px;
  }

  .contact-area .opening-time {
    display: inline-block;
    width: 100%;
  }

  .contact-area .opening-time ul li p {
    margin-bottom: 8px;
  }

  .ctc1 .ctc-box {
    width: 100%;
    display: inline-block;
    border: 1px solid #ced4da;
    border-radius: 8px;
    padding: 38px 30px;
    transition: all 0.5s ease 0s;
  }

  .ctc1 .ctc-box ul {
    z-index: 9;
    position: relative;
    padding: 16px 5px;
  }

  .ctc1 .ctc-box ul li p {
    color: #fff;
  }

  .ctc1 .ctc-box:hover {
    box-shadow: 2px 12px 39px -15px #171717;
  }

  .ctc1 .ctc-box .ctc-link {
    padding: 0 20px;
  }

  .ctc1 .ctc-box .ctc-link p {
    margin-bottom: 15px;
  }

  .ctc1 .ctc-box .ctc-link ul {
    padding: 0;
  }

  .ctc1 .ctc-box .ctc-link ul li {
    display: inline-block;
    margin-right: 20px;
  }

  .ctc1 .ctc-box .ctc-link ul li a i {
    color: #6b6b6b;
    font-size: 30px;
    transition: all 0.5s ease 0s;
  }

  .ctc1 .ctc-box .ctc-link ul li a i:hover {
    color: #fc328a;
  }

  .ctc1 .ctc-box-img {
    background-image: url("https://kwm-user-data.s3.eu-west-2.amazonaws.com/kwm_web/1.png");
    width: 100%;
    height: 100%;
    position: relative;
  }

  .ctc1 .ctc-box-img:before {
    position: absolute;
    content: "";
    background-color: rgba(252, 50, 138, 0.8);
    border-radius: 8px;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .ctc-form1 {
    background: #f7f7f7;
  }

  .ctc-form1 .bttn .btn {
    padding-left: 60px;
    padding-right: 60px;
  }

  /*4.7.2 contact us end*/
  /*4.7.3 404 error*/
  .error-page-area {
    padding: 350px 0;
  }

  .error-page-area .content-full h1,
  .error-page-area .content-full h2,
  .error-page-area .content-full h3,
  .error-page-area .content-full h4,
  .error-page-area .content-full h5,
  .error-page-area .content-full h6 {
    font-size: 100px;
    color: #fc328a;
    margin-bottom: 10px;
  }

  .error-page-area .content-full h2 {
    color: #6b6b6b;
    font-size: 22px;
    margin-bottom: 10px;
  }

  .error-page-area .content-full p {
    margin-bottom: 10px;
  }

  .error-page-area .content-full a {
    font-size: 30px;
    color: #fc328a;
  }

  /*4.7.3 404 error*/
  /*4.7  pages end*/
  /*4.8 ---------------------
classes start------------*/
  /*4.8.1 single-class start*/
  .single-class .single-content .single-img img {
    width: 100%;
  }

  .single-class .single-content .single-title h4 {
    color: #171717;
    font-size: 35px;
  }

  .single-class .search-box {
    position: relative;
  }

  .single-class .search-box input {
    width: 100%;
    height: 53px;
    padding: 9px 0 5px 22px;
    border: 1px solid transparent;
    background: #ececec;
  }

  .single-class .search-box input::placeholder {
    color: #6b6b6b;
    font-size: 20px;
  }

  .single-class .search-box a {
    position: absolute;
    top: 0;
    right: 5%;
  }

  .single-class .search-box a i {
    font-size: 20px;
    color: #6b6b6b;
    line-height: 2.5;
  }

  .single-class .single-list h4 {
    color: #6b6b6b;
    font-size: 22px;
  }

  .single-class .single-list ul li {
    width: 100%;
    display: inline-block;
  }

  .single-class .single-list ul li a {
    padding: 12px;
    color: #6b6b6b;
    font-size: 18px;
    border-top: 1px solid #a6a6a6;
    width: 100%;
    display: inline-block;
    transition: all 0.5s ease 0s;
  }

  .single-class .single-list ul li:last-child a {
    border-bottom: 1px solid #a6a6a6;
  }

  .single-class .single-list ul li:hover a {
    margin-left: 5px;
    color: #fc328a;
  }

  /*4.8.1 single-class end*/
  /*4.8.2 Booking start*/
  .booking-schedule ul li {
    color: #6b6b6b;
    font-size: 15px;
    display: block;
    width: 100%;
    margin-bottom: 15px;
    line-height: 20px;
  }

  .booking-schedule ul li i {
    color: #6b6b6b;
    float: left;
    margin-right: 10px;
  }

  /*4.8.2 Booking end*/
  /*4.8 classes-------------
end---------------------*/
  /*4.9----------------
shop start ----------*/
  /*4.9.1 shopping start-*/
  .shopping-area .section-title p {
    margin-bottom: 20px;
  }

  /*4.9.1 shopping end-*/
  /*4.9.2 single-product start-*/
  /*single-information-area*/
  .single-information-area .single-pro-tab-content .tab-content a img {
    width: 100%;
  }

  .single-information-area .single-pro-tab-content .single-product-tab {
    margin-top: 20px;
  }

  .single-information-area .single-pro-tab-content .single-product-tab li {
    float: left;
    width: 30.3%;
    margin-right: 15px;
  }

  .single-information-area .single-pro-tab-content .single-product-tab li:nth-child(3) {
    margin-right: 0px;
  }

  .single-information-area .single-pro-tab-content .single-product-tab li a {
    display: block;
    padding: 0;
    position: relative;
  }

  .single-information-area .single-pro-tab-content .single-product-tab li a img {
    width: 100%;
  }

  .single-information-area .product-info h3 a {
    color: #6b6b6b;
    font-size: 22px;
    line-height: 22px;
  }

  .single-information-area .product-info .pro-rating {
    width: 100%;
    float: left;
  }

  .single-information-area .product-info .pro-rating i {
    color: #dca044;
    float: left;
  }

  .single-information-area .product-info .pro-rating .review {
    margin-left: 10px;
    float: left;
  }

  .single-information-area .product-info .pro-rating .review p {
    color: #6b6b6b;
    line-height: 14px;
  }

  .single-information-area .product-info .pro-price {
    float: left;
    width: 100%;
  }

  .single-information-area .product-info .pro-price p {
    color: #6b6b6b;
    display: block;
    font-size: 20px;
  }

  .single-information-area .product-info .pro-price p span {
    color: #fc328a;
    margin-right: 20px;
  }

  .single-information-area .product-info .stock {
    width: 100%;
    float: left;
    color: #6b6b6b;
  }

  .single-information-area .product-info .stock p i {
    color: #6b6b6b;
    margin-right: 5px;
  }

  .single-information-area .product-info .product-desc {
    padding: 20px 0;
    width: 100%;
    float: left;
    border-top: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
  }

  .single-information-area .product-info .product-action {
    width: 100%;
    float: left;
  }

  .single-information-area .product-info .product-action .cart-plus-minus {
    float: left;
    position: relative;
  }

  .single-information-area .product-info .product-action .cart-plus-minus input {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: 1px solid #cccccc;
    color: #6b6b6b;
    height: 45px;
    text-align: center;
    width: 150px;
  }

  .single-information-area .product-info .product-action .cart-plus-minus div {
    right: 10%;
    top: 40%;
    color: #6b6b6b;
    cursor: pointer;
    line-height: 12px;
    position: absolute;
    font-size: 25px;
  }

  .single-information-area .product-info .product-action .cart-plus-minus div.dec {
    top: 35%;
    left: 10%;
    right: auto;
    font-size: 50px;
  }

  .single-information-area .product-info .pro-button-top {
    margin-left: 20px;
    display: inline-block;
  }

  .single-information-area .product-info .pro-button-top a {
    background: #fc328a;
    border: 1px solid transparent;
    color: white;
    display: inline-block;
    padding: 10.5px 40px;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
  }

  .single-information-area .product-info .pro-button-top a:hover {
    background: #e20d69;
    border: 1px solid transparent;
  }

  .single-information-area .category {
    display: inline-block;
    width: 100%;
    float: left;
  }

  .single-information-area .category p {
    font-size: 16px;
    margin-bottom: 0;
    width: 100%;
    float: left;
  }

  .single-information-area .category p span {
    margin-left: 8px;
    color: #171717;
  }

  .single-information-area .category ul {
    margin-top: 10px;
    width: 100%;
    float: left;
  }

  .single-information-area .category ul li {
    display: inline-block;
    margin-right: 8px;
    font-size: 15px;
    color: #6b6b6b;
  }

  .single-information-area .category ul li a i {
    color: #6b6b6b;
    font-size: 15px;
    transition: all 0.5s ease 0s;
  }

  .single-information-area .category ul li a i:hover {
    color: #fc328a;
  }

  /*pro-list-tab*/
  .pro-list-tab .pro-list .nav {
    margin-bottom: 40px;
    border-bottom: 1px solid #ddd;
  }

  .pro-list-tab .pro-list .nav .nav-item .nav-link,
  .pro-list-tab .pro-list .nav .tab-action .cta-box .nav-tabs .nav-item.show .nav-link,
  .tab-action .cta-box .nav-tabs .pro-list-tab .pro-list .nav .nav-item.show .nav-link,
  .pro-list-tab .pro-list .nav .single-tab-area .single-tab .nav-tabs .nav-item.show .nav-link,
  .single-tab-area .single-tab .nav-tabs .pro-list-tab .pro-list .nav .nav-item.show .nav-link {
    padding: 12px 18px;
    color: #6b6b6b;
    border-radius: inherit;
    font-size: 16px;
    font-weight: 700;
    transition: all 0.5s ease 0s;
    position: relative;
  }

  .pro-list-tab .pro-list .nav .nav-item .nav-link.active,
  .pro-list-tab .pro-list .nav .nav-item .nav-link:hover {
    color: #fc328a;
  }

  .pro-list-tab .pro-list .nav .nav-item .nav-link.active:before,
  .pro-list-tab .pro-list .nav .nav-item .nav-link:hover:before {
    width: 100%;
  }

  .pro-list-tab .pro-list .nav .nav-item .nav-link:before,
  .pro-list-tab .pro-list .nav .tab-action .cta-box .nav-tabs .nav-item.show .nav-link:before,
  .tab-action .cta-box .nav-tabs .pro-list-tab .pro-list .nav .nav-item.show .nav-link:before,
  .pro-list-tab .pro-list .nav .single-tab-area .single-tab .nav-tabs .nav-item.show .nav-link:before,
  .single-tab-area .single-tab .nav-tabs .pro-list-tab .pro-list .nav .nav-item.show .nav-link:before {
    content: "";
    position: absolute;
    background: #fc328a;
    height: 2px;
    width: 0%;
    bottom: 0;
    right: 0;
    transition: all 0.5s ease 0s;
  }

  .pro-list-tab .pro-list .tab-content p {
    font-size: 14px;
  }

  .pro-list-tab .pro-list .tab-content ul li {
    color: #6b6b6b;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .pro-list-tab .pro-list .tab-content ul li i {
    color: #6b6b6b;
    margin-right: 10px;
  }

  /*4.9.2 single-product end-*/
  /*4.9.3 shopping cart start*/
  /*-------------------------------------
23.	Cart Page Start Here
---------------------------------------*/
  .cart-btn {
    background: #f4f4f4;
    padding: 10px;
    display: block;
  }

  .cart-btn .btn-coupon {
    text-align: left;
    display: inline-block;
  }

  .cart-btn .btn-coupon input {
    border: 1px solid #6b6b6b;
    color: #6b6b6b;
    outline: none;
    background: transparent;
    padding: 8px;
    border-radius: 5px;
  }

  .cart-btn .btn-coupon input::placeholder {
    color: #6b6b6b;
  }

  .cart-btn .btn-coupon a.primary-btn {
    background: #fc328a;
    color: #fff;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 5px;
    display: inline-block;
    text-decoration: none;
    margin-left: 10px;
  }

  .cart-btn .btn-coupon a.primary-btn:hover {
    background: #fc328a;
    color: #fff;
  }

  .cart-btn .total-update {
    text-align: right;
    display: inline-block;
    float: right;
  }

  .cart-btn .total-update input {
    color: #fff;
    outline: none;
    background: #2c2c2c;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    margin-right: 10px;
    transition: all 0.4s ease 0s;
  }

  .cart-btn .total-update input:hover {
    background: #fc328a;
    color: #fff;
  }

  .cart-btn .total-update a.btn-bk {
    color: #fff;
    background: #2c2c2c;
    padding: 10px 20px;
    border-radius: 5px;
    display: inline-block;
    text-decoration: none;
  }

  .cart-btn .total-update a.btn-bk:hover {
    background: #fc328a;
    color: #fff;
  }

  .cart-total {
    margin-top: 50px;
  }

  .cart-total h3 {
    font-weight: 700;
    text-transform: uppercase;
  }

  .cart-total .cart-bg span {
    color: #2c2c2c;
    font-size: 16px;
    text-transform: uppercase;
  }

  .cart-total .total {
    padding: 10px;
    margin-top: 15px;
    background: #fc328a;
    color: #fff;
  }

  .cart-total .total span {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
  }

  .table-content table {
    background: #fff none repeat scroll 0 0;
    border-color: #fff;
    border-radius: 0;
    border-style: solid;
    border-width: 1px 0 0 1px;
    margin: 0 0 60px;
    text-align: center;
    width: 100%;
  }

  .table-content table thead {
    background: #fc328a;
  }

  .table-content table th {
    border-top: medium none;
    font-weight: normal;
    padding: 20px 10px;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    white-space: nowrap;
  }

  .table-content table td {
    border-top: medium none;
    font-size: 13px;
    padding: 5px 10px;
    vertical-align: middle;
  }

  .table-content table td input {
    background: #e5e5e5 none repeat scroll 0 0;
    border: medium none;
    border-radius: 3px;
    color: #2c2c2c;
    font-size: 15px;
    font-weight: normal;
    height: 40px;
    padding: 0 5px 0 10px;
    text-align: center;
    width: 60px;
    outline: none;
  }

  .table-content table td.product-subtotal {
    font-size: 14px;
    font-weight: bold;
    width: 120px;
    color: #171717;
  }

  .table-content table td.product-name {
    width: 270px;
  }

  .table-content table td.product-name a {
    font-size: 14px;
    font-weight: 700;
    margin-left: 10px;
    color: #2c2c2c;
    text-decoration: none;
  }

  .table-content table td.product-name a:hover .buttons-cart a:hover {
    color: #fc328a;
  }

  .table-content table td.product-thumbnail {
    width: 130px;
  }

  .table-content table td.product-remove {
    width: 150px;
  }

  .table-content table td.product-remove i {
    display: inline-block;
    font-size: 20px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    width: 40px;
    color: #2c2c2c;
  }

  .table-content table td.product-remove i:hover {
    color: #fc328a;
  }

  .table-content table td.product-quantity {
    width: 180px;
  }

  .table-content table td.product-price {
    width: 130px;
  }

  .table-content table .product-price .amount {
    font-size: 15px;
    font-weight: 700;
    color: #171717;
  }

  .buttons-cart {
    margin-bottom: 30px;
    overflow: hidden;
  }

  /*4.9.3 shopping cart end*/
  /*4.9.4 checkout start*/
  .guest-register h5 {
    font-size: 15px;
    font-weight: normal;
    color: #171717;
    margin-bottom: 5px;
  }

  .guest-register p {
    font-size: 14px;
  }

  .guest-register .register-method {
    margin-top: 10px;
    margin-bottom: 30px;
    float: left;
    width: 100%;
  }

  .guest-register .register-method input {
    font-size: 13px;
    color: #171717;
    margin-top: 4px;
    display: inline-block;
    line-height: 13px;
    float: left;
  }

  .guest-register .register-method label {
    color: #171717;
    font-size: 13px;
    margin-left: 16px;
    text-transform: uppercase;
    margin-bottom: 0;
    line-height: 13px;
  }

  .guest-register .register-method .register,
  .guest-register .register-method .guest {
    float: left;
    width: 100%;
  }

  .guest-register ul {
    margin-top: 20px;
  }

  .guest-register ul li a {
    font-size: 14px;
    color: #6b6b6b;
    transition: all 0.5s ease 0s;
    cursor: pointer;
  }

  .guest-register ul li a i {
    font-size: 14px;
    color: #6b6b6b;
    margin-right: 10px;
  }

  .guest-register ul li a:hover {
    margin-left: 5px;
    color: #fc328a;
  }

  .guest-register .bttn {
    margin-top: 45px;
  }

  .guest-register .bttn .btn {
    border: 1px solid #a6a6a6;
    border-radius: inherit;
    padding: 12px 50px;
  }

  .guest-register p.feild {
    color: #fc328a;
  }

  .guest-register .login {
    display: block;
    width: 100%;
  }

  .guest-register .login a {
    font-size: 14px;
    color: #6b6b6b;
    display: inline-block;
    float: left;
    margin-top: 10px;
  }

  .guest-register .login a:hover {
    color: #fc328a;
  }

  .guest-register .login .bttn {
    margin-top: 10px;
  }

  .guest-register .form-group input {
    border-radius: inherit;
    height: 50px;
  }

  .form-group h4 {
    font-size: 14px;
    color: #6b6b6b;
    margin-bottom: 5px;
  }

  .form-group h4 span {
    color: #fc328a;
  }

  /*4.9.4 checkout end*/
  /*4.9-----------------
shop end-------------*/
  /*04	Footer*/
  .footer-area .footer-content {
    margin-bottom: 40px;
  }

  .footer-area p {
    color: #ffffff;
    margin-bottom: 30px;
  }

  .footer-area .add-info {
    margin-top: 25px;
  }

  .footer-area .add-info p {
    margin-bottom: 10px;
  }

  .footer-area .add-info p a {
    margin-right: 10px;
  }

  .footer-area .news-info .footer-title h3 {
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    color: #ffffff;
  }

  .footer-area .news-info .subscribe {
    position: relative;
  }

  .footer-area .news-info .subscribe input {
    background: transparent none repeat scroll 0 0;
    border: 1px solid #fff;
    color: #fff;
    height: 45px;
    padding: 15px;
    width: 100%;
  }

  .lni {
    color: #fff;
  }

  .footer-area .news-info .subscribe a {
    background: #fff;
    height: 45px;
    line-height: 45px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 45px;
  }

  .footer-area .news-info .subscribe a i {
    color: #171717;
  }

  .footer-area .news-info .news-detail {
    display: inline-block;
    border-top: 1px solid #a6a6a6;
    display: inline-block;
    padding: 12px 0;
  }

  .footer-area .news-info .news-detail img {
    width: 20%;
    float: left;
  }

  .footer-area .news-info .news-detail p {
    display: block;
    font-size: 13px;
    overflow: hidden;
    padding-left: 10px;
    margin-bottom: 0;
  }

  .footer-area .news-info .nws-bar {
    border: inherit !important;
    padding-top: 0 !important;
  }

  .footer-area .news-info .footer-social ul li {
    display: inline-block;
    margin: 30px 18px 0 0;
  }

  .footer-area .news-info .footer-social ul li a i {
    background: #fff none repeat scroll 0 0;
    border-radius: 2px;
    color: #171717;
    font-size: 21px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    width: 28px;
    transition: all 0.5s ease 0s;
  }

  .footer-area .news-info .footer-social ul li a i:hover {
    background: #fc328a;
  }

  .footer-area .open-hrs ul li {
    border-top: 1px solid #a6a6a6;
    padding: 8px 0;
    text-transform: capitalize;
  }

  .footer-area .open-hrs ul li span {
    float: right;
  }

  .footer-area .open-hrs ul li:first-child {
    padding-top: 0;
    border: inherit;
  }

  .footer-area .instra {
    position: relative;
  }

  .footer-area .instra h4 {
    position: relative;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    color: #ffffff;
    text-align: center;
    letter-spacing: 1px;
  }

  .footer-area .instra h4:before {
    background: #a6a6a6;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    top: 50%;
    width: 300px;
  }

  .footer-area .instra h4:after {
    background: #a6a6a6;
    content: "";
    height: 1px;
    right: 0;
    position: absolute;
    top: 50%;
    width: 400px;
  }

  /*footer-gallery*/
  .footer-gallery .item {
    transition: all 0.5s ease 0s;
    position: relative;
  }

  .footer-gallery .item a {
    display: block;
  }

  .footer-gallery .item a img {
    width: 100%;
  }

  .footer-gallery .item a i {
    position: absolute;
    font-size: 30px;
    color: #fff;
    cursor: pointer;
    top: 45%;
    left: 45%;
    opacity: 0;
    transition: all 0.5s ease 0s;
  }

  .footer-gallery .item:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: #fc328a;
    top: 0;
    left: 0;
    opacity: 0;
    transition: all 0.5s ease 0s;
  }

  .footer-gallery .item:hover:before {
    opacity: 0.5;
  }

  .footer-gallery .item:hover a i {
    opacity: 1;
  }

  .copyright {
    background: #171717;
  }

  .copyright h4 {
    color: #fff;
    text-align: center;
    font-size: 15px;
    text-transform: capitalize;
  }

  .copyright h4 span {
    color: #fc328a;
  }

  /*04	Footer*/

  /*# sourceMappingURL=style.css.map */
  .container-fluid {
    width: 100% !important;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  .container-fluid-2 {
    width: 80% !important;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  a.primary-overlay {
    width: 373px;
  }

  .about-area.pad90.last-bg {
    background: #f2f2f2;
  }

  .about-area.pad90.start-about {
    padding: 60px 0px 0px;
  }

  p {
    margin-top: 0;
    margin-bottom: 10px !important;
  }

  .about-opening img {
    width: 100%;
  }

  .section-title.text-header h3 {
    text-align: center;
  }

  .top-sec {
    position: relative;
    top: -64px;
  }

  .test-img img {
    border-radius: 50%;
    padding: 10px;
    width: 26%;
  }

  .subs-title i {
    color: #00cc68;
    font-size: 48px;
  }

  .video-freme {
    margin-top: 45px;
    float: left;
  }

  .video-heading {
    font-weight: 700;
    text-align: center;
  }

  .pricing-area.text-center.pad90.fisrt-videobg {
    background: #eee;
  }

  .pricing-area.text-center.pad90.fisrt-videobg p {
    color: #333;
  }

  .service-title .table {
    color: #333;
    margin-top: 46px;
    border: 1px solid #e5e5e5;
  }

  .service-title .table td {
    font-size: 15px;
    letter-spacing: 0;
    border: 1px solid #dee2e6;
    text-transform: unset;
    text-align: center;
  }

  .service-title .table th {
    color: #fc328a;
    font-size: 15px;
    letter-spacing: 0;
    border: 1px solid #dee2e6;
    text-transform: unset;
    text-align: center;
  }

  .pricing-area.text-center.pad90 .with-first {
    height: 700px;
    margin-bottom: 63px;
  }

  .bttn.again {
    margin-top: 20px;
  }

  /*.about-area .about-opening {
    padding: 26px 0px !important;
}*/
  .mt20 img {
    margin-right: 10px;
    margin-top: 5px;
  }

  .footer-gallery.owl-carousel.transfo {
    margin-bottom: 20px;
  }

  img {
    vertical-align: middle;
    border-style: none;
  }

  .img-width img {
    width: 100%;
  }

  .high {
    background: #fc328a !important;
  }

  .line-color li {
    color: #fff !important;
    padding: 12px 0;
    width: 90%;
    margin: 0 8px;
    font-size: 16px;
    border-top: 1px solid #cccccc;
  }

  .news-info.ftr-algn img {
    width: 30%;
  }

  .card-body a {
    color: #fc328a;
  }

  .card-body a:hover {
    text-decoration: underline;
  }

  .plus-img img {
    width: 4%;
    position: absolute;
    margin: 23px;
  }

  .first-child span {
    text-decoration: line-through;
    font-size: 16px;
  }

  .mb-2 {
    margin-bottom: 0.5rem !important;
  }

  .container-fluid-2 .mb-0 {
    margin-bottom: 0 !important;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 1px;
    color: #fc328a;
    padding-bottom: 8px;
  }

  .flex-fill.ml-3 {
    color: #525f7f;
    line-height: 1.5;
    font-size: 16px;
    font-weight: 300;
  }

  label.form-check-label {
    color: #525f7f;
    line-height: 1.5;
    font-size: 16px;
    font-weight: 300;
  }

  .shadow-sm {
    box-shadow: 0px 8px 32px #e7e7e7;
  }

  .col-auto.d-none.d-lg-block {
    margin: 0;
    padding: 0;
    border: 2px solid #fff;
  }

  .card-text.mb-auto.proper {
    font-weight: 600;
    color: #333;
  }

  .p-4.d-flex.flex-column.position-static .mb20 {
    margin-bottom: 0px;
  }

  .mb-auto,
  .my-auto {
    margin-bottom: 80px !important;
  }

  /*.d-none {
    display: block !important;
}*/

  .mb-4,
  .my-4 {
    /*margin-bottom: 1.5rem!important;
    margin-left: 20px;*/
  }

  h5.text-uppercase.text-muted.mb-0.card-title {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0;
  }

  .w-100 {
    width: 100% !important;
  }

  .services-content .card-header {
    padding: 1px !important;
  }

  .border-0.card-header h3 {
    color: #32325d;
    font-family: inherit;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 0;
    font-size: 1.0625rem;
  }

  p.text-primary.pointer.d-flex.justify-content-between.card-text .lni {
    color: #5e72e4;
  }

  .p-4 {
    padding: 1.5rem !important;
  }

  #more {
    display: none;
  }

  #more2 {
    display: none;
  }

  #more3 {
    display: none;
  }

  #more4 {
    display: none;
  }

  #more5 {
    display: none;
  }

  #more6 {
    display: none;
  }

  #more7 {
    display: none;
  }

  #more8 {
    display: none;
  }

  #more9 {
    display: none;
  }

  #more10 {
    display: none;
  }

  #more11 {
    display: none;
  }

  #more12 {
    display: none;
  }

  #more13 {
    display: none;
  }

  #more14 {
    display: none;
  }

  #more15 {
    display: none;
  }

  #more16 {
    display: none;
  }

  .col.p-4.d-flex.flex-column.position-static a {
    color: #fc328a;
    cursor: pointer;
  }

  .section-title.text-center.videos-reviews .mb20 {
    margin-bottom: 28px;
  }

  .section-title.text-center {
    text-align: center !important;
    position: relative;
  }

  .pricing-area h3 {
    color: #171717;
  }

  .contact-area h3 {
    color: #171717;
  }

  h3.rockstarts {
    color: #171717 !important;
    margin-top: 10px;
  }

  .col.p-4.d-flex.flex-column.position-static span {
    color: #fc328a;
    cursor: pointer;
  }

  .my-masonry-grid {
    display: -webkit-box;
    /* Not needed if autoprefixing */
    display: -ms-flexbox;
    /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px;
    /* gutter size offset */
    width: auto;
  }

  .my-masonry-grid_column {
    padding-left: 30px;
    /* gutter size */
    background-clip: padding-box;
  }

  /* Style your items */
  .my-masonry-grid_column>div {
    /* change div to reference your elements you put in <Masonry> */
  }

  .about-area h3 {
    color: #171717;
  }

  .popover-inner .card-body {
    padding: 20px 10px;
  }

  .table thead th {
    vertical-align: top;
  }

  #hero {
    width: 100%;
    background: url("https://kwm-user-data.s3.eu-west-2.amazonaws.com/kwm_web/s-3.jpg") top center;
    background-size: cover;
    position: relative;
  }

  #hero:before {
    content: "";
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }

  #hero .container {
    position: relative;
    padding-top: 74px;
    padding-bottom: 74px;
    text-align: center;
  }

  #hero h1 {
    margin: 0;
    font-size: 35px;
    font-weight: 700;
    line-height: 64px;
    color: #fc328a;
    font-family: "Poppins", sans-serif;
  }

  #hero h1 span {
    color: #fc328a;
  }

  #hero h2 {
    color: rgba(255, 255, 255, 0.9);
    margin: 10px 0 0 0;
    font-size: 24px;
  }

  #hero .icon-box {
    padding: 20px 10px;
    transition: ease-in-out 0.3s;
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 100%;
    text-align: center;
  }

  #hero .icon-box i {
    font-size: 32px;
    line-height: 1;
    color: #fc328a;
  }

  #hero .icon-box h3 {
    font-weight: 600;
    margin: 10px 0 0 0;
    padding: 0;
    line-height: 1;
    line-height: 26px;
    font-size: 18px !important;
  }

  #hero .icon-box h3 a {
    color: #fff;
    transition: ease-in-out 0.3s;
  }

  #hero .icon-box h3 a:hover {
    color: #fc328a;
  }

  #hero .icon-box:hover {
    border-color: #fc328a;
  }

  @media (min-width: 1600px) {

    .header .navbar.navbar-dark {
      padding: 10px 150px;
    }

  }

  @media (min-width: 1440px) and (max-width: 1599px) {

    .header .navbar.navbar-dark {
      padding: 10px 100px;
    }

  }

  @media (min-width: 1024px) and (max-width: 1439px) {

    .header .navbar.navbar-dark {
      padding: 10px 30px;
    }

  }

  @media (min-width: 1024px) {
    #hero {
      background-attachment: fixed;
    }
  }

  @media (max-width: 995px) {
    #hero {
      height: auto;
    }

    #hero h1 {
      font-size: 28px;
      line-height: 36px;
    }

    #hero h2 {
      font-size: 20px;
      line-height: 24px;
    }
  }

  .weeks_6 a:focus {
    color: #5e72e4;
  }
}