.live-classes-container {
    height: 500px;
}

#card-desc {
    overflow: hidden;
}

#card-desc.card-desc-show {
    height: auto;
}

#card-desc.card-desc-hidden {
    height: 0;
}

.card-total-class {
    width: 120px;
}

.recorded-class-checkout {
    white-space: nowrap;
}

@media (min-width: 1440px) {
    .recording-card-xxl-2 {
        flex: 0 0 17% !important;
        max-width: 17% !important;
    }
    .recording-card-xxl-10 {
        flex: 0 0 83% !important;
        max-width: 83 !important;
    }
}
