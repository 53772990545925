.bg-color{
    background-color: #ffffff;
}

.image-container {
    position: relative;
}

.gradient-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 30%; /* Adjust this value to control the width of the gradient */
    height: 100%;
    background: linear-gradient(to left, rgba(0, 0, 0, 0), #000000);
    z-index: 2;
}
.card-title {
    z-index: 3;
}
.img-height-fixedss {
    max-width: 100%;
    object-fit: cover;
}

.card-border-class{
    border-radius:18px;
}

@media screen and (max-width: 400px) {
    .img-height-fixedss {
        height: 100px;
    }
}

@media screen and (min-width: 401px) and (max-width: 1080px) {
    .img-height-fixedss {
        height: 130px;
    }
}

@media screen and (min-width: 1081px) {
    .img-height-fixedss {
        height: 160px;
    }
    .card-border-class{
        border-radius:26px !important;
    }
}
