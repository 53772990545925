.page-head.overlay:before {
    background: #010528;
    content: "";
    opacity: 0.6;
}
.overlay::before {
    background: #171717;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.8;
    position: absolute;
    top: 0;
    width: 100%;
}
.about-desc{
    font-family: 'DM Sans', sans-serif !important;
}
.padd-bot{
    padding-bottom: 100px !important;
}
.landing-site p {
font-size: 16px !important;
}
.about-col-desc{
    font-size: 20px !important;
}
.head-font{
    font-family:'Forum' !important;
    font-weight: 600 !important;
    color:black !important;
    font-size: 40px !important;
}
.about-about{
    position: relative !important;
    z-index: '2' !important;
 }
.parallax {
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    background-position: 50% 0;
    background-size: cover;
}
.testimonial-sec{
    text-align: center;
    padding: 55px 100px 85px;
    align-items: center;
}
.section-head{
    font-family: 'DM Sans', sans-serif;
    color:black !important;
}
.head-size{
    font-size:24px !important;
}

.about-sec{
    text-align: center;
    align-items: center;
    padding: 125px 100px 125px;
}
.about-bg {
    background-color: none !important;
}
.testimonial-down{
    // display: flex !important ;
}

.testimonial-card {
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
    background-color: rgba(255, 255, 255, 0.425) !important;
    border-color: black !important;
    border: 0.5px solid rgba(128, 128, 128, 0.274) !important;
    border-radius: 30px 0px;

    @media (max-width: 992px) {
        border-radius: 20px 0px;
    }

    .testimonial-card-text {
        font-family: 'DM Sans', sans-serif;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.50);
        overflow:hidden;
    }
    .testimonial-card-text:hover {
         cursor: pointer;
         overflow: visible;
    }

    .testimonial-card-footer {
        gap: 10px;

        .testimonial-card-icon {
            background-color: #764979;
            border-radius: 50%;
            padding: 5px;
        }
        .testimonial-card-head-name {
            font-family:'Forum' !important;
            font-weight: 400 !important; 
            font-size:28px !important;
            color: rgba(0, 0, 0, 1);
        }

        .testimonial-card-name {
            font-family: 'DM Sans', sans-serif;
            font-weight: 400 !important;
            font-size:24px !important;
            color: rgba(33, 37, 41, 0.5);
        }

        .testimonial-card-subtext {
            color: orange;
            font-family: 'DM Sans', sans-serif;
            font-weight: 400 !important;
            font-size:20px !important;
            margin-bottom: 0 !important;
        }
    }
}
.row-size{
    padding-bottom: 75px;
}
// .col-size{
   
//     flex: 0 0 48%; /* Adjust width as needed */
//   margin: 10px;
// }
#promotion-page {
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 60vh;

    @media (max-width: 992px) {
        min-height: 40vh;
    }

    .promotion-container {
        overflow: hidden;

        .book-now-btn {
            background-color: #764979;
            border-radius: 20px 0px;
            font-family: 'DM Sans', sans-serif;
            font-weight: 500;
            color: white;
            padding: 14px 40px;
            transition: all 100ms ease-out;
            border: none;
            width: fit-content;

            &:hover {
                background-color: #764979;
                opacity: 0.8;
            }

            @media (max-width: 992px) {
                border-radius: 10px 0px;
                padding: 7px 20px;
            }
        }

        .promotion-img {
            height: 100%;
            width: auto;
            object-fit: contain;
        }
    }
}
.testimonial-card-col-container {
    height: 450px;

    .empty-card-div {
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
        background-color: #f1d0ff;
        border-radius: 30px 0px;

        @media (max-width: 992px) {
            border-radius: 20px 0px;
        }

        height: 300px;
    }

.page-head {
    padding: 200px 100px 150px;
}
.overlay {
    position: relative;
}
.section-title.text-header h3 {
    text-align: center;
}
.page-head .section-title h3 {
    font-size: 55px;
    margin-bottom: 20px;
    color: black !important ;
}
.overlay .section-title h3 {
    color: black !important;
}
.section-title h3 {
    color: #171717;
    font-size: 40px;
    line-height: 40px;
}
h1, h2, h3, h4, h5, h6 {
    font-family: Helvetica, Sans-Serif;
    font-weight: 700;
    margin: 0;
}
.breadcrumb {
    background-color: rgba(0, 0, 0, 0);
    justify-content: center;
}
.breadcrumb li {
    color: white;
    display: inline-block;
    font-size: 18px;
    font-weight: 400;
    text-transform: capitalize;
    margin: 0 5px;
}
.breadcrumb li a {
    color: #fc328a;
}
a {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-decoration: none;
}
.about-area.pad90.start-about {
    padding: 60px 0px 0px;
}
.pad90 {
    padding: 60px 0;
}
.mb20 {
    margin-bottom: 20px;
}
.full-width {
    width: 100%;
}
img {
    vertical-align: middle;
    border-style: none;
}
.section-title h3 {
    color: #171717;
    font-size: 40px;
    line-height: 40px;
}
p {
    margin-top: 0;
    margin-bottom: 10px !important;
}
p {
    font-size: 15px;
    color: #171717;
    line-height: 1.6rem;
    letter-spacing: .5px;
}
.about-area .about-opening {
    padding: 0px 0px;
}
.about-opening img {
    width: 100%;
}
.more-about {
    font-size: 18px;
    font-weight: 700;
}
.able {
    color: #333;
}
.section-title.text-center {
    text-align: center!important;
}
.trainers-area .trainer-pro .trainer-img {
    position: relative;
    margin-bottom: 40px;
}
.trainers-area .trainer-pro .trainer-img img {
    width: 100%;
    border-radius: 5%;
}
.about-area.pad90.last-bg {
    background: #f2f2f2;
}
.about-down {
    font-size: 15px;
    color: #171717;
    line-height: 2.2rem;
    letter-spacing: .5px;
}

ul {
    list-style: outside none none;
    margin-bottom: 0;
    padding-left: 0;
}
}