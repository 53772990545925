/* 05 responsive styles */
/* 05 responsive styles */
@media screen and (max-width: 991px) and (min-width: 768px) {

  /*02 Navbar Styles*/
  .header .navbar {
    background: rgba(0, 0, 0, 0.8);
  }

  .header .navbar .navbar-toggler {
    color: #fff;
    border-color: 1px solid #fff;
    background: #fc328a;
    border-radius: 10px;
    padding: 2px 10px;
  }

  .header .navbar .navbar-header {
    width: 100%;
  }

  .header .navbar .navbar-header .navbar-brand {
    padding: 0;
    width: 40%;

    img {
      height: 60px;
      width: auto;
    }

  }

  .header .navbar .navbar-header .navbar-brand img {
    width: 100%;
  }

  .header .navbar .navbar-header .navbar-toggler {
    color: #fff;
    border: 1px solid #fff;
    background: #fc328a;
    border-radius: 10px;
    padding: 1px 8px;
    float: right;
    margin: auto 0;
  }

  .header .navbar .navbar-nav .nav-item.active {
    background-color: inherit;
  }

  .header .navbar .navbar-nav .nav-item.active .nav-link {
    background-color: inherit;
    color: #fc328a;
  }

  .header .navbar .navbar-nav .nav-item.active .nav-link:before {
    text-shadow: inherit;
    color: inherit;
  }

  .header .navbar .navbar-nav .nav-item .nav-link {
    line-height: 50px;
  }

  .header .navbar .navbar-nav .nav-item .nav-link:before {
    content: none;
  }

  .header.fixed-top {
    position: inherit;
  }

  /*3.2 slider area*/
  .slider-area1 .tp-caption .slide-text-one h1 {
    font-size: 30px !important;
    line-height: 60px !important;
  }

  .slider-area1 .tp-caption .slide-text-one h1 span {
    font-size: 30px !important;
    color: #fc328a;
  }

  .slider-area1 .tp-caption .slide-text-two h1 {
    font-size: 18px !important;
  }

  /*3.3 features area*/
  .features-area .features-body {
    transform: translateY(0px);
  }

  .features-area .features-body .features-box {
    width: 100%;
  }

  .feature-box {
    transform: translateY(0);
  }

  /*3.4 call to action1 */
  .call-to-action1 {
    margin-bottom: 60px;
  }

  .call-to-action1 .cta-box {
    text-align: center;
  }

  .call-to-action1 .cta-box h4.sub-title {
    margin-top: 30px;
  }

  .call-to-action1 .bttn {
    text-align: center;
  }

  .call-to-action1.tab-action {
    margin-bottom: inherit;
  }

  .action-box h4 {
    margin-top: 40px;
    font-size: 30px;
  }

  .call-to-action .bttn {
    text-align: center;
    margin-top: 20px;
  }

  /*3.5 portfolio area*/
  .port-area2 .section-title.text-left {
    text-align: center !important;
  }

  .port-area2 .portfolio-nav ul {
    width: 100%;
  }

  .port-area2 .portfolio .portfolio-masonry-item {
    width: 100%;
    margin: 0;
  }

  .port-zoom.mt60 {
    margin-top: 30px;
  }

  /*3.7 schedule area*/
  .schedule-area .schdl-tab-area {
    width: 15%;
  }

  .schedule-area .schdl-tab-area li a {
    font-size: 16px;
    padding: 13px 12px;
  }

  .schedule-area .tab-content {
    width: 85%;
  }

  .parallax {
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: 50% 0;
    background-size: cover;
  }

  .schedule-area2 .schedule-tab-content .tab-pane ul li .schedule-desk {
    padding: 20px 0;
    width: 70%;
    float: left;
    margin-left: 35px;
    display: inline-block;
  }

  /*04 Footer*/
  /*4.1 blog area start*/
  .blog-box .blog-date {
    top: 55%;
  }

  .blog-area .blog-portfolio .portfolio-masonry-item {
    margin: 0px 0px;
  }

  .blog-page .blog-date {
    top: 0;
  }

  /*4.2 client area */
  .trainers-area .trainer-pro .trainer-bio h4 {
    color: #000;
    font-size: 16px;
    margin-bottom: 5px;
  }

  /*4.3 testimonial area*/
  .testimonial-area .testimonial-box {
    padding: 40px 0;
  }

  .testimonial-area .testimonial-box .test-img,
  .testimonial-area .testimonial-box .test-content {
    float: inherit;
    width: 100%;
    text-align: center;
  }

  .testimonial-area .testimonial-box .test-content {
    margin-top: 10px;
  }

  /*4.6.2 about-service start*/
  .calculate-area {
    background: rgba(252, 50, 138, 0.8);
  }

  /*4.6.3 about-service-single start*/
  .single-tab-content {
    margin-top: 40px;
  }

  /*checkout*/
  .guest-register .bttn {
    margin-top: 45px;
    margin-bottom: 40px;
    text-align: left !important;
  }

  .guest-register .login .bttn {
    margin-top: 10px;
    width: 100%;
    float: left;
  }

  /* global-styles*/
  .pad90 {
    padding: 60px 0;
  }

  .page-head {
    padding: 200px 50px 150px;
  }

  .card-body {
    padding: 12px 30px 30px 22px;
  }

  .img3 {
    width: 100%;
  }

  .sm-mb-30 {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) {

  .bg-white.border-0.card-header a {
    padding: 10px;
  }

  .heading-small.text-muted.mb-4 {
    margin-left: -4px !important;
  }

  .header .navbar .navbar-toggler {
    border: 1px solid black;
    background: #fc328a;
    border-radius: 10px;
    padding: 1px 8px;
  }

  .header .navbar .navbar-header {
    width: 100%;
  }

  .header .navbar .navbar-header .navbar-brand {
    padding: 0;
  }

  .header .navbar .navbar-header .navbar-brand img {
    width: 200px;
    height: auto;
  }

  .header .navbar .navbar-header .navbar-toggler {
    color: black;
    background: #171717;
    border-radius: 12px;
    padding: 4px;
    float: right;
  }

  .section-title h3 {
    font-size: 26px;
    line-height: 40px;
  }

  .container-fluid-2 {
    width: 96% !important;
  }

  .row.g-0.border.rounded.overflow-hidden.flex-md-row.mb-4.shadow-sm.h-md-250.position-relative {
    margin-left: 0px;
  }

  .col-auto.d-none.d-lg-block {
    display: block !important;
  }

  .header .navbar .navbar-collapse {
    background: rgba(0, 0, 0, 0.8);
  }

  .header .navbar-nav .nav-link {
    padding: .625rem 0;
    color: #ffffff !important;
  }

  .page-head .section-title h3 {
    font-size: 23px !important;
    margin-bottom: 2px;
    color: #fff;
  }

  .page-head {
    padding: 45px !important;
  }

  .top-sec {
    position: relative;
    top: 15px;
  }

  .card-img,
  .card-img-top {
    border-radius: 0px !important;
  }

  .text-box.p-5 h1 {
    font-size: 20px !important;
  }

  .text-box.p-5 {
    margin: 40px 00px;
  }

  .text-box.p-5 h3 {
    font-size: 12px !important;
    line-height: 32px;
  }

  .text-box.p-lg h1 {
    font-size: 20px !important;
  }

  .text-box.p-lg h3 {
    font-size: 12px !important;
    line-height: 32px;
  }

  .slick-dots,
  .slick-next,
  .slick-prev {
    display: none !important;
  }

  .text-box.p-5 h3 {
    font-weight: 500;

  }

  .text-box.p-lg h3 {
    font-weight: 500;

  }

  .call-to-action h4 {
    font-size: 26px;
  }

  .header .navbar .navbar-nav .nav-item.active {
    background-color: inherit;
  }

  .header .navbar .navbar-nav .nav-item.active .nav-link {
    background-color: inherit;
    color: #fc328a;
  }

  .header .navbar .navbar-nav .nav-item.active .nav-link:before {
    text-shadow: inherit;
    color: inherit;
  }

  .header .navbar .navbar-nav .nav-item .nav-link {
    line-height: 40px;
  }

  .header .navbar .navbar-nav .nav-item .nav-link:before {
    content: none;
  }

  .header.fixed-top {
    position: inherit;
  }

  /*3.2 slider area*/
  .slider-area1 .tp-caption.slide-text-one h1 {
    font-size: 30px !important;
    line-height: 60px !important;
  }

  .slider-area1 .tp-caption.slide-text-one h1 span {
    font-size: 30px !important;
    color: #fc328a;
  }

  .slider-area1 .tp-caption.slide-text-two h1 {
    font-size: 18px !important;
  }

  /*3.3 features area*/
  .features-area .features-body {
    transform: translateY(0px);
    display: inherit;
  }

  .features-area .features-body .features-box {
    width: 100%;
  }

  .features-area .features-body .features-box:nth-child(even) .features-elements {
    order: 0;
  }

  .features-area .features-body .features-box:nth-child(even) .features-box-img {
    order: 1;
  }

  .feature-action .feature-box {
    transform: translateY(0);
  }

  .feature-action .feature-box .features-body {
    display: inline-block;
  }

  /*3.4 call to action1 */
  .call-to-action1 {
    margin-bottom: 0 !important;
    margin-bottom: 40px;
  }

  .call-to-action1 .cta-box {
    text-align: center;
    padding: 5px 0px 45px;
  }

  .call-to-action1 .cta-box h4.sub-title {
    margin-top: 30px;
  }

  .call-to-action1 .bttn {
    text-align: center;
  }

  .action-box h4 {
    margin-top: 40px;
  }

  /*3.5 portfolio area*/
  .port-area2 .section-title.text-left {
    text-align: center !important;
  }

  .port-area2 .portfolio-nav ul {
    width: 100%;
  }

  .port-area2 .portfolio .portfolio-masonry-item {
    width: 100%;
    margin: inherit;
  }

  /*3.7 schedule area*/
  .schedule-area .schdl-tab-area {
    width: 20%;
  }

  .schedule-area .schdl-tab-area li a {
    font-size: 14px;
    padding: 6px 5px;
  }

  .schedule-area .tab-content {
    width: 80%;
  }

  .schedule-area .tab-content .tab-pane .schdl-box {
    padding: 20px 10px;
    width: 120px;
  }

  .schedule-area .tab-content .tab-pane .schdl-box h5 {
    font-size: 16px;
    margin-bottom: 0;
  }

  .schedule-area .tab-content .tab-pane .schdl-box p {
    font-size: 11px;
    color: #444;
  }

  .schedule-area2 .schdl-tab-area2.nav .nav-item .nav-link {
    padding: 10px 0px;
    font-size: 15px;
  }

  .schedule-area2 .schdl-tab-area2.nav .nav-item .nav-link span {
    margin-top: 0px;
  }

  .schedule-area2 .schedule-tab-content .tab-pane ul li .schedule-img {
    width: 100%;
  }

  .schedule-area2 .schedule-tab-content .tab-pane ul li .schedule-desk {
    width: 100%;
    margin-left: 0;
    padding: 20px 18px;
    border: 1px solid #ddd;
  }

  .schedule-area2 .schedule-tab-content .tab-pane ul li .schedule-desk h3 {
    font-size: 16px;
  }

  /*3.8 product box*/
  .product-area .product-box .cart-dtl h4 {
    text-align: center;
  }

  .product-area .product-box .cart-dtl h4 span {
    float: left;
    width: 100%;
    margin-top: 5px;
  }

  .product-area .product-box .cart-dtl .add-cart {
    display: inline-block;
    margin-top: 20px;
  }

  .product-area .product-box .cart-dtl .add-cart a {
    font-size: 14px;
    padding: 8px 20px;
    background: #fc328a;
    color: #fff;
    border-radius: 30px;
    display: inline-block;
  }

  /*3.9 call to action*/
  .call-to-action h4,
  .call-to-action .bttn {
    text-align: center;
  }

  .call-to-action .bttn {
    margin-top: 30px;
  }

  /*04	Footer*/
  .footer-area .instra h4:before,
  .footer-area .instra h4:after {
    width: 100px;
  }

  .footer-area .footer-content {
    margin-bottom: 20px;
  }

  .ftr-algn {
    margin-top: 40px;
  }

  /*4.3 testimonial area*/
  .testimonial-area .testimonial-box {
    padding: 40px 0;
  }

  .testimonial-area .testimonial-box .test-img,
  .testimonial-area .testimonial-box .test-content {
    float: inherit;
    width: 100%;
    text-align: center;
  }

  .testimonial-area .testimonial-box .test-content {
    margin-top: 10px;
  }

  /*4.1 blog area start*/
  .blog-box .blog-date {
    top: 33%;
  }

  .blog-area .blog-portfolio .portfolio-masonry-item {
    margin: 0px 0px;
  }


  .mb-4,
  .my-4 {
    display: flow-root;
  }

  .container-fluid {
    width: 96% !important;
  }

  .blog-page .blog-date {
    top: 0;
  }

  /*portfolio area*/
  .port-zoom.mt60 {
    margin-top: 30px;
  }

  /*4.2 client area */
  .client .item {
    width: 100%;
  }

  .client .item a {
    display: block;
    text-align: center;
  }

  .client .item a img {
    width: 50%;
  }

  /*4.2 client area */
  .trainers-area .trainer-pro .trainer-bio h4 {
    color: #000;
    font-size: 16px;
    margin-bottom: 5px;
  }

  /*4.6.3 about-service-single start*/
  .single-tab-content {
    margin-top: 40px;
  }

  .single-tab-area .single-tab-content .services-content ul li {
    width: 100%;
  }

  /*4.6.2 about-service start*/
  .calculate-area {
    background: rgba(252, 50, 138, 0.8);
  }

  /*4.8.2 Booking start*/
  .booking-schedule {
    margin-top: 40px;
  }

  /*4.9.2 single-product start-*/
  .product-info {
    margin-top: 40px;
  }

  .test-img-2 {
    float: inherit;
    width: 100%;
    text-align: center;
  }

  .test-img-2 img {
    margin-right: 0;
  }

  .slick-slide img {
    display: revert;
  }

  .testimonial-item p {
    text-align: center;
    width: 300px;
  }

  .testimonial-item h4 {
    text-align: center;
  }

  .testimonial-item {
    padding: 40px 44px;
  }

  /*shopping cart*/
  .cart-btn .total-update,
  .cart-btn .btn-coupon {
    text-align: right;
    display: flex;
    float: inherit;
    justify-content: space-between;
    margin-top: 20px;
  }

  /*checkout*/
  .guest-register .bttn {
    margin-top: 45px;
    margin-bottom: 40px;
    text-align: left !important;
  }

  .guest-register .login .bttn {
    margin-top: 10px;
    width: 100%;
    float: left;
  }

  /* global-styles*/
  .pad90 {
    padding: 10px 0;
  }

  .page-head .section-title h3 {
    font-size: 33px;
    margin-bottom: 20px;
    color: #fff;
  }

  .tab-action .cta-box h4 {
    font-size: 28px;
  }

  .tab-action .cta-box h5 {
    font-size: 32px;
  }

  .schedule-heading {
    font-size: 21px;
  }

  /*  .text-left {
    text-align: center !important; }*/

  /*  .page-head {
    padding: 200px 50px 150px; }*/
  .single-tab-area .single-tab .nav-tabs .nav-item .nav-link,
  .single-tab-area .single-tab .nav-tabs .nav-item.show .nav-link {
    padding: 12px 18px;
  }

  .mb300 {
    line-height: 34px;
    font-size: 24px;
  }

  .page-head {
    padding: 45px;
  }

  .card-body {
    padding: 12px 30px 30px 22px;
  }

  .img3 {
    width: 100%;
  }

  .sm-mb-30 {
    margin-bottom: 30px;
  }
}

/* 05 responsive styles */

/* Optional, different gutter size on mobile */
@media (max-width: 800px) {
  .my-masonry-grid {
    margin-left: -15px;
    /* gutter size offset */
  }

  .my-masonry-grid_column {
    padding-left: 15px;
    /* gutter size offset */
  }

  .my-masonry-grid_column>div {
    margin-bottom: 15px;
    /* space between items */
  }
}