.content-class{
    padding-left: 5%;
 
}
.content-class-new{
    padding-left: 2%;
 
}
.landing-site{
    background-color: #ffffff !important;
}
.form-mar{
 
     @media (min-width: 100px) {
        margin: 0%;
    }
    @media (min-width: 992px) {
        margin:0% 10%;
    }
}
.input-text-style::placeholder{
    color:grey !important;

}
.testimonial-secss{
    text-align: center;
    padding: 100px 100px 0px;
    align-items: center;
}
.center-flower{
    text-align: center;
    padding: 55px 100px 85px;
    align-items: center;
}
.head-font{
    font-family:'Forum' !important;
    font-weight: 600 !important;
    color:black !important;
    font-size: 40px !important;
}
.head-fonts{
    font-family:'Forum' !important;
    font-weight: 600 !important;
    color:black !important;
    font-size: 20px !important;
}
.head-font-new{
    font-family:'Forum' !important;
    font-weight: 600 !important;
    color:black !important;
    font-size: 50px !important;
}
.pad-bo{
    padding-bottom: 100px;
}
.btn-colors{
    background-color: black !important;
}
.logo-colors{
    color:black !important;
}
.mar-top{
    margin-top: 10%;
}
.horizontal-class{
    width: 100%;
    margin-left: 1.5%;
}
.form-class{
    border-radius: 0px 25px 0px 25px !important;
}
.form-border-set{
    border:1px solid #8898aa;
    border-radius: 0px 80px!important;
    padding: 8% 6%; 
}