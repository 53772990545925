#home-page {
    background-color: #fff;
    position: relative;

    .section-small-heading {
        font-family: 'DM Sans', sans-serif;
        font-weight: 500;
        color: #808080;
        text-transform: uppercase;
        text-align: center;
    }

    .section-heading {
        font-family: 'Abril Fatface', cursive;
        font-weight: 400;
        color: #333333;
        white-space: pre-line;
        text-align: center;
    }

    .section-subheading {
        font-family: 'DM Sans', sans-serif;
        font-weight: 500;
        color: #808080;
        white-space: pre-line;
        text-align: center;
    }

    .left-twig-svg {
        position: absolute;
        top: 0;
        left: 0px;
        height: 300px;
        width: auto;
        object-fit: contain;
    }

    .right-twig-svg {
        position: absolute;
        bottom: 0;
        right: 0;
        height: 300px;
        width: auto;
        object-fit: contain;
    }

    #banner-page {
        min-height: 100vh;
        position: relative;

        .banner-container {
            min-height: 80%;

            .image-holder-col {
                position: relative;

                @media (max-width: 992px) {
                    order: 1 !important;
                    padding: 10px;
                }

                .colored-yoga-svg {
                    position: absolute;
                    bottom: 0;
                    left: 10px;
                    width: 30%;
                    object-fit: contain;
                }

                .banner-image-wrapper {
                    height: 90%;
                    width: 80%;
                    padding: 10px;
                    float: right;

                    .banner-image {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        border-top-left-radius: 1000px;
                        border-top-right-radius: 1000px;
                    }
                }
            }

            .content-holder-col {
                @media (max-width: 992px) {
                    order: 2 !important;
                }

                .small-flower-svg {
                    height: 90px;
                    width: 90px;
                    object-fit: contain;
                }

                .banner-tagline {
                    font-family: 'DM Sans', sans-serif;
                    font-weight: 500;
                    color: #808080;
                    text-transform: uppercase;
                    text-align: center;
                }

                .banner-heading {
                    font-family: 'Abril Fatface', cursive;
                    font-weight: 400;
                    color: #333333;
                    white-space: pre-line;
                }

                .banner-subheading {
                    font-family: 'DM Sans', sans-serif;
                    font-weight: 500;
                    color: #808080;
                    white-space: pre-line;
                }

                .buttons-container {
                    gap: 10px;
                    .banner-button {
                        width: fit-content;
                        border: none;
                        background-color: #27392a;
                        font-family: 'DM Sans', sans-serif;
                        font-weight: 700;
                        padding: 10px 35px;
                        border-radius: 0px 20px;
                        color: #fff;
                        a {
                            color: #fff !important;
                        }
                    }
                }
            }
        }
    }

    #services-page {
        position: relative;
        padding: 40px 0;

        .services-container {
            z-index: 1;

            .service-card {
                .service-image {
                    border-radius: 60px 0px;
                    object-fit: cover;

                    @media (max-width: 992px) {
                        border-radius: 40px 0px;
                    }
                }

                .service-heading {
                    font-family: 'Abril Fatface', cursive;
                    font-weight: 400;
                    color: #333333;
                    white-space: pre-line;
                }

                .service-text {
                    font-family: 'DM Sans', sans-serif;
                    font-weight: 500;
                    color: #808080;
                    white-space: pre-line;
                }
            }
        }
    }

    #about-page {
        position: relative;
        padding: 40px 0;

        .about-container {
            z-index: 1;

            .slick-carousel-image {
                border-radius: 60px 0px !important;

                @media (max-width: 992px) {
                    border-radius: 40px 0px !important;
                }
            }

            .slick-carousel-caption {
                font-family: 'DM Sans', sans-serif;
                font-weight: 400;
                color: #333333;
                white-space: pre-line;
            }
        }
    }

    #fitness-stars-page {
        position: relative;
        padding: 40px 0;

        .fitness-stars-container {
            .slick-carousel-image {
                border-radius: 60px 0px !important;

                @media (max-width: 992px) {
                    border-radius: 40px 0px !important;
                }
            }
        }
    }

    #specialities-page {
        position: relative;
        padding-top: 40px;
        padding-bottom: 40px;

        @media (min-width: 992px) {
            padding-bottom: 150px;
        }

        .specialities-container {
            z-index: 1;

            .service-card {
                gap: 20px;

                .service-card-icon-container {
                    background-color: #764979;
                    padding: 8px;
                    height: fit-content;
                    width: fit-content;
                    border-radius: 50%;
                }

                .service-card-heading {
                    font-family: 'Abril Fatface', cursive;
                    font-weight: 400;
                    color: #333333;
                    white-space: pre-line;
                }

                .service-card-text {
                    font-family: 'DM Sans', sans-serif;
                    font-weight: 500;
                    color: #808080;
                    white-space: pre-line;
                }
            }

            .image-container-ellipsis {
                background-repeat: no-repeat;
                background-size: contain;
                min-height: 350px;
                position: relative;

                .service-card-ind-0 {
                    //top left
                    position: absolute;
                    top: 0;
                    left: 10%;
                    transform: translate(-100%, 0%);
                    max-width: 300px;
                }

                .service-card-ind-1 {
                    //top right
                    position: absolute;
                    top: 0;
                    right: 10%;
                    transform: translate(100%, 0%);
                    max-width: 300px;
                }

                .service-card-ind-2 {
                    // mid left
                    position: absolute;
                    left: -7%;
                    top: 45%;
                    transform: translate(-100%, -50%);
                    max-width: 300px;
                }

                .service-card-ind-3 {
                    // mid right
                    position: absolute;
                    right: -7%;
                    top: 45%;
                    transform: translate(100%, -50%);
                    max-width: 300px;
                }
                .service-card-ind-4 {
                    // bottom left
                    position: absolute;
                    left: 0%;
                    bottom: 12%;
                    transform: translate(-100%, 0%);
                    max-width: 300px;
                }
                .service-card-ind-5 {
                    // bottom right
                    position: absolute;
                    right: 0%;
                    bottom: 12%;
                    transform: translate(100%, 0%);
                    max-width: 300px;
                }
                .service-card-ind-6 {
                    flex-direction: column-reverse !important;
                    align-items: center !important;
                    position: absolute;
                    bottom: -16%;
                    left: 50%;
                    transform: translate(-50%, 50%);
                    max-width: 300px;
                    .service-card-heading, .service-card-text {
                        text-align: center !important;
                    }
                }
            }
        }
    }

    #pricing-page {
        position: relative;
        padding: 40px 0;

        .pricing-container {
            z-index: 1;

            .product-card {
                border-radius: 80px 0px;
                overflow: hidden;
                background-color: white;

                @media (max-width: 992px) {
                    border-radius: 40px 0px;
                }

                box-shadow: 0px 2px 26px 0px rgba(0, 0, 0, 0.15);

                .product-card-image-container {
                    position: relative;

                    .product-card-image {
                        object-fit: cover;
                        width: 100%;
                        height: 250px;
                        object-position: top;
                    }

                    .flower-container {
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        transform: translate(-50%, 50%);
                        padding: 5px;
                        background-color: white;
                        border-radius: 50%;
                        overflow: hidden;

                        .flower-wrapper {
                            background-color: #764979;
                            border-radius: 50%;
                            height: 100px;
                            width: 100px;
                        }
                    }
                }

                .product-card-content-container {
                    .product-card-heading {
                        font-family: 'Abhaya Libre', serif;
                        font-weight: 800;
                        text-transform: capitalize;
                    }

                    .product-card-price {
                        font-family: 'Abhaya Libre', serif;
                        font-weight: 800;
                        text-transform: capitalize;
                        font-size: 48px !important;
                    }

                    .product-card-price-for {
                        font-family: 'DM Sans', sans-serif;
                        font-weight: 400;
                        color: rgba(33, 37, 41, 0.50);
                    }

                    .product-card-feature-container {
                        gap: 10px;

                        .product-card-feature-icon {
                            background-color: #764979;
                            padding: 1px;
                            border-radius: 50%;
                        }

                        .product-card-feature-text {
                            margin-bottom: 0 !important;
                            font-family: 'DM Sans', sans-serif;
                            font-weight: 400;
                            color: #333333;
                        }
                    }

                    .product-card-button {
                        background-color: #764979;
                        border-radius: 20px 0px;
                        font-family: 'DM Sans', sans-serif;
                        font-weight: 500;
                        color: white;
                        padding: 14px 40px;
                        transition: all 100ms ease-out;
                        border: none;
                        align-self: center;

                        &:hover {
                            background-color: #764979;
                            opacity: 0.8;
                        }

                        @media (max-width: 992px) {
                            border-radius: 10px 0px;
                        }
                    }
                }
            }
        }

        .more-packages-btn {
            background-color: #764979;
            border-radius: 20px 0px;
            font-family: 'DM Sans', sans-serif;
            font-weight: 500;
            color: white;
            padding: 14px 40px;
            transition: all 100ms ease-out;
            border: none;
            align-self: center;
            z-index: 2;

            &:hover {
                background-color: #764979;
                opacity: 0.8;
            }

            @media (max-width: 992px) {
                border-radius: 10px 0px;
            }
        }
    }

    #testimonial-page {
        position: relative;
        padding: 40px 0;
        background-color: #FBF1FF;
        overflow: hidden;

        .testimonial-container {
            .testimonial-stats-container {
                gap: 20px;

                .testimonial-small-imgs-container {
                    gap: -10px;

                    .testimonial-small-imgs-wrapper {
                        padding: 3px;
                        border-radius: 50%;
                        background-color: white;

                        .testimonial-img {
                            height: 50px;
                            width: 50px;
                            object-fit: cover;
                            border-radius: 50%;
                        }
                    }
                }

                .testimonial-stats-heading {
                    font-family: 'Abhaya Libre', serif;
                    font-weight: 800;
                    text-transform: capitalize;
                    margin-bottom: 0 !important;
                }

                .testimonial-stats-subheading {
                    font-family: 'DM Sans', sans-serif;
                    font-weight: 400;
                    color: rgba(0, 0, 0, 0.5);
                    margin-bottom: 0 !important;
                }
            }
        }

        .testimonial-card-col-container {
            height: 450px;

            .empty-card-div {
                box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
                background-color: #f1d0ff;
                border-radius: 30px 0px;

                @media (max-width: 992px) {
                    border-radius: 20px 0px;
                }

                height: 300px;
            }

            .testimonial-card {
                box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
                background-color: white;
                border-radius: 30px 0px;

                @media (max-width: 992px) {
                    border-radius: 20px 0px;
                }

                .testimonial-card-text {
                    font-family: 'DM Sans', sans-serif;
                    font-weight: 500;
                    color: rgba(0, 0, 0, 0.50);
                }

                .testimonial-card-footer {
                    gap: 10px;

                    .testimonial-card-icon {
                        background-color: #764979;
                        border-radius: 50%;
                        padding: 5px;
                    }

                    .testimonial-card-name {
                        font-family: 'DM Sans', sans-serif;
                        font-weight: 700;
                        color: #000;
                    }

                    .testimonial-card-subtext {
                        color: rgba(0, 0, 0, 0.50);
                        font-family: 'DM Sans', sans-serif;
                        font-weight: 500;
                        margin-bottom: 0 !important;
                    }
                }
            }
        }
    }

    #promotion-page {
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 60vh;

        @media (max-width: 992px) {
            min-height: 40vh;
        }

        .promotion-container {
            overflow: hidden;

            .book-now-btn {
                background-color: #764979;
                border-radius: 20px 0px;
                font-family: 'DM Sans', sans-serif;
                font-weight: 500;
                color: white;
                padding: 14px 40px;
                transition: all 100ms ease-out;
                border: none;
                width: fit-content;

                &:hover {
                    background-color: #764979;
                    opacity: 0.8;
                }

                @media (max-width: 992px) {
                    border-radius: 10px 0px;
                    padding: 7px 20px;
                }
            }

            .promotion-img {
                height: 100%;
                width: auto;
                object-fit: contain;
            }
        }
    }
}

.about-read-more {
    transition: all 100ms ease-out;
}

.about-read-more-inactive {
    opacity: 0.5;
    height: 0;
    overflow: hidden;
}


.about-read-more-active {
    opacity: 1;
    height: 100%;
}

.coach-img img {
    width: 100%;
    height: 300px;
    object-fit: cover;
}

.newsletter-modal {
    font-family: 'DM Sans', sans-serif !important;

    @media (min-width: 768px) {
        .content-container-row {
            min-height: 60vh;
        }
    }

    .common-field-box {
        border-radius: 12px !important;
        background-color: #ffffff !important;
        box-shadow: none !important;
        border: 2px solid #ececec !important;
        color: black !important;
    }
    .continue-btn-field-box {
        border-radius: 12px !important;
        background-color: black !important;
        box-shadow: none !important;
        color: #ffffff !important;
    }
}
