.linear-gradient {
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, #353535, rgba(75, 75, 75, 0));
    position: absolute;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    z-index: 2;
}

.modal-content{
    border-radius: 20px !important;
}

.text-font-size{
    font-weight: 300;
}