.ql-toolbar {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.ql-container {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 0 1px 3px #32325d26, 0 1px 0 #00000005;
}

.recipe img {
    width: 100%;
    height: auto;
}
.about-desc{
    font-family: 'DM Sans', sans-serif !important;
    font-weight: 500 !important;
    font-size: 24px !important;
    text-align: left !important;
    color:black !important;
}
.about-secs{
    text-align: center;
    align-items: center;
   margin-bottom:  125px;
}
.padd-bott{
    margin-bottom: 3%;
}