@media (min-width: 1441px) {
    .col-xxl-5 {
        -webkit-flex: 0 0 41.66666666%;
        flex: 0 0 41.66666666%;
        max-width: 41.66666666%;
    }
    .col-xxl-7 {
        -webkit-flex: 0 0 58.33333333%;
        flex: 0 0 58.33333333%;
        max-width: 58.33333333%;
    }
}