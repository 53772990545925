@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Forum&display=swap');

.subscription-page {
    font-family: 'DM Sans', sans-serif;
}

.subscription-features-bullet-point {
    margin-bottom: 0 !important;
}
.mobile-app-landing-page {
    overflow: hidden;
}

.menu-link {
    margin-left: 10px;
    margin-right: 10px;
    color: #000;
    text-align: center;
    font-family: DM Sans;
    font-size: 16px;
    font-weight: 500;
}

.menu-link:hover {
    color: rgb(0, 173, 239) !important;
}

.join-now {
    display: flex;
    width: 140px;
    height: 46px;
    padding: 13px 32.066px 13px 33px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 15px 0px;
    border: 1px solid rgba(33, 37, 41, 0.50);
    color: #000;
    font-family: DM Sans;
    font-size: 16px;
    font-weight: 500;
}

.join-now:hover{
    color: rgb(0, 173, 239) !important;
}

.mobile-menu-container {
    z-index: 100000;
    top: 0px;
    right: -1000px;
    position: fixed;
    transition: all 300ms ease;
}

.mobile-menu-container .filler {
    width: calc(100vw - 250px);
    height: 100vh;
}

.mobile-menu {
    width: 250px;
    background-color: #000000c5;
    height: 100vh;
    padding: 10px;
}

.mobile-menu .menu-link {
    text-align: left !important;
    color: #fff;
}

.mobile-menu .join-now {
    border-color: #fff;
    color: #fff;
}

.mobile-menu .close-icon {
    font-size: 20px;
    color: #fff;
    margin-left: 10px;
}

.mobile-visible {
    right: 0px;
}

.navbar-toggler {
    color: rgb(0, 173, 239) !important;
    font-size: 32px;
}


@media (min-width: 768px) {
    .subscription-features-container {
        border-right: 1px solid #808080;
        padding: 50px !important;
    }

    .subscription-plans-container {
        padding: 50px !important;
    }

    .subscription-plan-card {
        width: 100% !important;
    }
}

@media (max-width: 768px) {
    .subscription-plan-card {
        width: 100% !important;
    }

    .subscription-plans-card-container {
        width: 100% !important;
        max-width: 100% !important;
    }

    .create-subscription-content-container .subscription-features-container {
        display: none !important;
    }
}

@media (min-width: 1024px) {
    .subscription-page .create-subscription-content-container {
        padding: 0% 10% !important;
    }
}

@media (min-width: 2000px) {
    .subscription-plans-card-container {
        min-width: 700px;
    }
}

/* Subscription Features Page  */
.section-2 .features .content-container .list-item {
    font-weight: 400;
    font-size: 15px;
}
.interested-user-modal {
    font-family: 'DM Sans', sans-serif !important;
}
.interested-user-modal .modal-content {
    border-radius: 0px 40px !important;
    overflow: hidden !important;
}
.interested-user-modal .common-field-box {
    border-radius: 12px !important;
    background-color: #ffffff !important;
    box-shadow: none !important;
    border: 2px solid #ececec !important;
    color: black !important;
}

.interested-user-modal .continue-btn-field-box {
    border-radius: 12px !important;
    background-color: black !important;
    box-shadow: none !important;
    color: #ffffff !important;
}

.account-modal {
    font-family: 'DM Sans', sans-serif !important;
}
.account-modal .modal-content {
    border-radius: 0px 40px !important;
    overflow: hidden !important;
}
.account-modal .common-field-box {
    border-radius: 12px !important;
    background-color: #ffffff !important;
    box-shadow: none !important;
    border: 2px solid #ececec !important;
    color: black !important;
}

.account-modal .continue-btn-field-box {
    border-radius: 12px !important;
    background-color: black !important;
    box-shadow: none !important;
    color: #ffffff !important;
}

@media (max-width: 768px) {
    h1 {
        font-size: 30px !important;
    }

    h2 {
        font-size: 20px !important;
    }

    h3 {
        font-size: 15px !important;
    }

    p {
        font-size: 12px !important;
    }

    .subscription-button {
        font-size: 12px !important;
        width: 150px !important;
    }
    .subscription-button-product {
        font-size: 12px !important;
    }

    .contact-us-button {
        font-size: 12px !important;
        width: 150px !important;
    }

    .refer-button {
        font-size: 12px !important;
    }

    .section-1 {
        min-height: 110vh !important;
    }

    .section-1 .app-3-svg {
        display: none !important;
    }
    .section-1 .flower-img-container{
        display: none !important;
    }

    .section-1 .content-container {
        width: 100% !important;
        height: 100% !important;
        padding: 2% !important;
    }

    .section-1 .content-container .subscription-button {
        margin-top: 20px !important;
    }

    .section-1 .content-container .contact-us-button {
        margin-top: 20px !important;
    }
    .section-1 .content-container .img-container {
        min-height: 150px !important;
    }
    .section-1 .content-container .img-background-1 {
        border-radius: 30px 0px 30px 0px !important;
        
    }
    .section-2 .content-container {
        order: 1 !important;
    }

    .section-2 .img-container .img-background {
        border-radius: 30px 0px 30px 0px !important;
    }
    .section-2 .img-container .actual-img {
        border-radius: 30px 0px 30px 0px !important;
    }

    .section-2 .title {
        display: none !important;
    }

    .section-2 .features {
        padding: 0% !important;
    }

    .section-2 .features .app-img-container {
        height: 50vh !important;
        order: 2 !important;
    }

    .section-2 .features .app-img-container .app-1-svg {
        width: 40% !important;
    }

    .section-2 .features .content-container .list-item-container {
        margin: 2px 0px !important;
    }

    .section-3 .features {
        padding: 0% !important;
    }

    .section-3 .features .content-container .list-item-container {
        margin: 2px 0px !important;
    }

    .section-3 .features .app-img-container {
        height: 60vh !important;
    }

    .section-4 .features {
        padding: 0% !important;
    }

    .section-4 .features .img-container {
        height: 50vh !important;
    }

    .section-4 .features .content-container .title {
        font-size: 24px !important;
    }

    .section-4 .features .content-container .details {
        font-size: 13px !important;
    }

    .section-5 .content-container {
        padding: 3% !important;
    }

    .section-5 .content-container .content-container-inner {
        border-radius: 0px 30px 0px 30px !important;
    }

    .section-5 .product-container .product-card {
        border-radius: 0px 30px 0px 30px !important;
    }

    .section-6 {
        min-height: 100vh !important;
    }

    .section-6 .features {
        height: 35% !important;
        width: 90% !important;
        padding: 0% !important;
        border-radius: 0px 40px 0px 40px !important;
    }

    .section-6 .features .content-container {
        align-items: center !important;
    }
    .section-6 .features .content-container div {
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        justify-content: center !important;
        z-index: 4 !important;
        background-color: transparent !important;
    }

    .section-6 .features .content-container div p {
        text-align: center !important;
    }

    .section-6 .features .img-container {
        display: none !important;
    }
}

@media (min-width: 768px) {
    .subscription-feature-section {
        height: 100vh;
        width: 100%;
    }

    .section-1 .content-container {
        width: 60% !important;
    }

    .section-6 .mobile-only-img-container {
        display: none !important;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .section-1 .content-container {
        width: 70% !important;
    }
    .section-1 .app-3-svg {
        display: none !important;
    }
    .section-1 .flower-img-container{
        display: none !important;
    }
}

@media (min-width: 1024px) {
    .section-5 .title-text-container {
        width: 100% !important;
        height: 100% !important;
    }

    .section-5 .product-container-len-2 {
        padding: 5% 15% !important
    }
}

@media (min-width: 1440px) {
    h1 {
        font-size: 50px !important;
    }

    h2 {
        font-size: 35px !important;
    }

    h3 {
        font-size: 22px !important;
    }

    p {
        font-size: 18px !important;
    }

    .subscription-button {
        font-size: 20px !important;
        border-radius: 0px 20px 0px 20px !important;
    }

    .subscription-button-product {
        font-size: 20px !important;
        border-radius: 0px 20px 0px 20px !important;
    }

    .contact-us-button {
        font-size: 20px !important;
        border-radius: 20px 0px 20px 0px !important;
    }

    .refer-button {
        border-radius: 20px 0px 20px 0px !important;
        font-size: 20px !important;
    }
    .section-1 .logo-img {
        width: 200px !important;
    }

    .section-2 .img-container .img-background {
        border-radius: 120px 0px 120px 0px !important;
    }
    .section-2 .img-container .actual-img {
        border-radius: 120px 0px 120px 0px !important;
    }

    .section-4 .img-container .underlay {
        border-radius: 120px 0px 120px 0px !important;
    }

    .section-4 .img-container .overlay-img {
        border-radius: 120px 0px 120px 0px !important;
    }

    .section-5 .content-container {
        padding: 3% 10% !important;
    }

    .section-5 .content-container .content-container-inner {
        border-radius: 0px 150px 0px 150px !important;
    }

    .section-5 .product-container .product-card {
        border-radius: 0px 120px 0px 120px !important;
    }

    .section-6 .features {
        border-radius: 0px 150px 0px 150px !important;
    }

    .section-6 .img-container .underlay {
        border-radius: 120px 0px 120px 0px !important;
    }

    .section-6 .img-container .overlay-img {
        border-radius: 120px 0px 120px 0px !important;
    }
    .interested-user-modal .modal-content {
        border-radius: 0px 80px !important;
        overflow: hidden !important;
    }
    .account-modal .modal-content {
        border-radius: 0px 80px !important;
        overflow: hidden !important;
    }
}

@media (min-width: 2000px) {
    h1 {
        font-size: 100px !important;
    }

    h2 {
        font-size: 60px !important;
    }

    h3 {
        font-size: 40px !important;
    }

    p {
        font-size: 30px !important;
    }

    .section-1 .logo-img {
        width: 400px !important;
    }
    .section-1 .flower-img-container .img-background-2 {
        border-radius: 0px 90px 0px 90px !important;
    }

    .section-1 .content-container .img-background-1 {
        border-radius: 120px 0px 120px 0px !important;
    }

    .subscription-button {
        font-size: 30px !important;
        width: 300px !important;
        border-radius: 0px 25px 0px 25px !important;
    }

    .subscription-button-product {
        font-size: 30px !important;
        border-radius: 0px 25px 0px 25px !important;
    }

    .contact-us-button {
        font-size: 30px !important;
        width: 300px !important;
        border-radius: 25px 0px 25px 0px !important;
    }

    .refer-button {
        font-size: 30px !important;
        border-radius: 25px 0px 25px 0px !important;
    }
    .interested-user-modal .modal-content {
        border-radius: 0px 120px !important;
        overflow: hidden !important;
    }
    .interested-user-modal .underlay {
        border-radius: 120px 0px !important;
    }
    .interested-user-modal .overlay-img {
        border-radius: 120px 0px !important;
    }

    .account-modal .modal-content {
        border-radius: 0px 120px !important;
        overflow: hidden !important;
    }
    .account-modal .underlay {
        border-radius: 120px 0px !important;
    }
    .account-modal .overlay-img {
        border-radius: 120px 0px !important;
    }
}

.bgr{
    background-color: #E4D0F4;
}
.bgl{
    background-color: #E4D0F4;
}

