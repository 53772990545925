.icon-size{
    font-size: 24px;
}
.button-styling{
    font-size:14px;
}

@media screen and (min-width: 1268px) {
    .icon-size{
        font-size: 36px;
    }
    .button-styling{
        font-size:20px;
    }
}
