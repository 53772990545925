@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.loader {
    border-color: $primary;
    border-bottom-color: $light;
    border-left-color: $light;
    animation: spin 2s cubic-bezier(0.1, 0.3, 0.5, 0.25) infinite;
}