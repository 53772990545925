.parallax {
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
}
.page-head {
    padding: 200px 100px 150px;
}
.overlay {
    position: relative;
}
.container-value{
    width:80%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media screen and (max-width: 768px ){
    .container-value{
        width:96% !important;
    }
    .padding-short{
        padding-left: 3px !important;
        padding-right: 3px !important;
    }
}
.overlay::before {
    background: #171717;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.8;
    position: absolute;
    top: 0;
    width: 100%;
}
.section-title.text-center {
    text-align: center!important;
}
.page-head .section-title h3 {
    font-size: 55px;
    margin-bottom: 20px;
    color: #fff;
}
.overlay .section-title h3 {
    color: #fff;
}
.section-title h3 {
    color: #171717;
    font-size: 40px;
    line-height: 40px;
}
.breadcrumb {
    background-color: rgba(0, 0, 0, 0);
    justify-content: center;
}
.breadcrumb li {
    color: white;
    display: inline-block;
    font-size: 18px;
    font-weight: 400;
    text-transform: capitalize;
    margin: 0 5px;
}
.breadcrumb li a {
    color: #fc328a;
}