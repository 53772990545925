@import "react-big-calendar/lib/sass/styles";

@media (max-width: 575px) {
    .rbc-toolbar {
        flex-direction: column;
        .rbc-toolbar-label {
            padding-top: 5px;
            padding-bottom: 5px;    
        }
    }
}
